import { API, Auth, Storage } from "aws-amplify";
import { AppEntityConfig } from "../redux/AppEntities";
import { AccountingUtils } from "revlock-accounting";
import UserActions from "redux/actions/user.actions";
import queryString from "query-string";
import {
    showAlert,
    showError,
    getMicroStrategyGroupName
} from "../utils/WebUtils";
import { Utils } from "revlock-webutils";

const customAuthorizationEnabled =
    process.env.REACT_APP_CUSTOM_AUTHORIZATION &&
    process.env.REACT_APP_CUSTOM_AUTHORIZATION === "true";

Storage.configure({ level: "public" });

const orgId = (state) => {
    let org =
        state &&
        state.currentOrganization &&
        state.currentOrganization.currentOrganization;
    if (!org) {
        throw Error("No organization selector, error while initiating update.");
    }
    return org.id;
};

export const PRIVATE_API_NAME = "revlock_api";
export const PUBLIC_API_NAME = "revlock_public_api";

export const fetcher = (entityName) =>
    async function(state, args) {
        let clientId = orgId(state);
        let config = AppEntityConfig(entityName);
        let getAllPath = config.getAllPath;

        if (config.paging) {
            let { currentPage, maxPerPage } = args;

            // Start index of items to show on screen
            args.start = currentPage * maxPerPage;

            // End index of items to show on screen.
            args.end = args.start + maxPerPage - 1;
        }

        Object.keys(args).forEach((arg) => {
            if (args[arg] instanceof Date) {
                args[arg] = args[arg].toISOString();
            }
        });

        return invokeApi(GET, `/private/${clientId}/${getAllPath}`, {
            queryStringParameters: args
        });
    };

export const fetcherCurrent = (entityName) =>
    async function(state, entityId) {
        const clientId = orgId(state);
        const path = AppEntityConfig(entityName).getPath;

        return invokeApi(GET, `/private/${clientId}/${path}/${entityId}`);
    };

export const fetcherCurrentTask = (entityName) =>
    async function(state, args) {
        let clientId = orgId(state);
        let { entityId } = args;

        return invokeApi(
            GET,
            `/private/${clientId}/activeTasks/all/${entityId}`,
            {
                queryStringParameters: args
            }
        );
    };

export const assignCurrentTask = (entityName) =>
    async function(state, params) {
        let clientId = orgId(state);

        let { taskId } = params;

        return invokeApi(POST, `/private/${clientId}/task/${taskId}/claim`, {
            body: params
        });
    };

export const onEntityAction = (entityName) =>
    async function(state, action, params) {
        let clientId = orgId(state);

        let { url, method } = action;

        return invokeApi(
            method || POST,
            `/private/${clientId}/${url}`,
            method !== GET
                ? {
                      body: params
                  }
                : {
                      queryStringParameters: params
                  }
        );
    };

export const creator = (entityName) =>
    async function(state, updates) {
        let clientId = orgId(state);
        let updatePath = AppEntityConfig(entityName).updatePath;

        return invokeApi(POST, `/private/${clientId}/${updatePath}`, {
            body: updates
        });
    };

export const updater = (entityName) =>
    async function(state, updates) {
        let clientId = orgId(state);
        let updatePath = AppEntityConfig(entityName).updatePath;

        return invokeApi(PUT, `/private/${clientId}/${updatePath}`, {
            body: updates
        });
    };

export const deleteAll = (entityName) =>
    async function(state, ids) {
        let clientId = orgId(state);
        let updatePath = AppEntityConfig(entityName).updatePath;

        return invokeApi(DELETE, `/private/${clientId}/${updatePath}`, {
            body: ids
        });
    };

export async function initDemoOrg(state, org) {
    return invokeApi(GET, `/private/${org.id}/_demoReLoad`);
}

export async function initProdTest(state, org) {
    return invokeApi(GET, `/private/${org.id}/_demoReferenceLoad`);
}

export async function getOrgConfigurations(clientId) {
    return invokeApi(GET, `/private/${clientId}/orgConfigs`);
}

export async function putOrgConfigurations(clientId, configs) {
    return invokeApi(PUT, `/private/${clientId}/orgConfigs`, {
        body: configs
    });
}

export async function deleteOrgConfigurations(clientId, orgConfigIds) {
    return invokeApi(DELETE, `/private/${clientId}/orgConfigs`, {
        body: orgConfigIds
    });
}

/**
 * Clear Backend Caches
 */
export async function clearRACache(orgId) {
    return invokeApi(POST, `/private/admin/${orgId}/clear-arrangements`);
}

/**
 * Impersonation
 */
export async function startImpersonation(impersonatedUser) {
    return invokeApi(PUT, `/private/admin/impersonate`, {
        body: { impersonatedUser }
    });
}

export async function endImpersonation() {
    return invokeApi(DELETE, `/private/admin/impersonate`);
}

/**
 * DB Size
 */

export async function getDBSize() {
    return invokeApi(GET, `/private/admin/redis/dbSize`);
}

/**
 * Demo environment details
 *
 * @returns {Promise<*|void|undefined>}
 */
export async function getDemoEnvironmentDetails() {
    const url = "/demoenvdetails";
    return invokeApi(GET, url, {}, PUBLIC_API_NAME);
}

/**
 * What mode is the system running..? This is either {mode: "r"} or {mode: "rw"}
 *
 * @returns {Promise<*|void|undefined>}
 */
export async function getMode(details) {
    const url = details ? `/mode?details=${details}` : `/mode`;
    return invokeApi(GET, url, {}, PUBLIC_API_NAME, true);
}

export async function getUserIdp(email, clientId, revrecPath) {
    const url = `/user-idp`;
    return invokeApi(
        POST,
        url,
        {
            body: {
                email: email,
                clientId: clientId,
                revrecPath: revrecPath
            }
        },
        PUBLIC_API_NAME,
        true
    );
}

/**
 * Gets actualUsername by username from user pool
 */
export async function getActualUsername(params = {}) {
    if (!params.username) {
        throw Error("Username is not provided in queryString");
    }
    const username = encodeURIComponent(params.username);
    const url = `/actualUsername?username=${username}`;
    return invokeApi(GET, url, {}, PUBLIC_API_NAME, true);
}

/**
 * Creates the new User to Cognito
 */
export async function createNewUser(params = {}) {
    if (!params.username) {
        throw Error("User is not provided in as parameter");
    }

    const url = `/create-new-user`;
    return invokeApi(
        POST,
        url,
        {
            body: {
                ...params
            }
        },
        PUBLIC_API_NAME,
        true
    );
}

/**
 * Registers the new User SignUp to keycloak broker
 */
export async function registerNewUserToKeycloak(params = {}) {
    if (!params.username || !params.family_name || !params.given_name) {
        throw Error("User is not provided in as parameter");
    }

    const url = `/kk-admin-register-new-user`;
    return invokeApi(
        POST,
        url,
        {
            body: {
                ...params
            }
        },
        PUBLIC_API_NAME,
        true
    );
}

/**
 * Confirms the new User SignUp to keycloak broker
 */
export async function confirmNewUserToKeycloak(params = {}) {
    if (!params.username) {
        throw Error("User is not provided in as parameter");
    }

    const url = `/kk-admin-confirm-new-user`;
    return invokeApi(
        PUT,
        url,
        {
            body: {
                ...params
            }
        },
        PUBLIC_API_NAME,
        true
    );
}

export async function getOrgProfile(clientId) {
    const url = `/org-profile?clientId=${clientId}`;
    return invokeApi(GET, url, {}, PUBLIC_API_NAME, true);
}

/**
 * Set the REVLOCK system mode that is used for upgrades.
 *
 * @param mode either "r" or "rw"
 * @returns {Promise<*|void|undefined>}
 */
export async function setMode(mode = "rw") {
    return invokeApi(PUT, `/private/admin/mode/${mode}`);
}

export async function saveInDirectExpenseItems(expenseItems, orgId) {
    const salesOrderId = expenseItems[0].salesOrderId;
    return invokeApi(
        PUT,
        `/private/${orgId}/indirect-expenses/${salesOrderId}`,
        {
            body: expenseItems
        }
    );
}

export async function saveExpenseItems(expenseItems, orgId) {
    const salesOrderId = expenseItems[0].salesOrderId;
    return invokeApi(PUT, `/private/${orgId}/direct-expenses/${salesOrderId}`, {
        body: expenseItems
    });
}

/**
 * Auto Suggest API
 */
export async function suggestOptions(params, orgId) {
    const toReturn = await invokeApi(
        GET,
        `/private/${orgId}/partialKeySearch?${queryString.stringify(params)}`
    );
    return toReturn;
}

export async function adminSuggestOptions(params) {
    const toReturn = await invokeApi(
        GET,
        `/private/admin/suggest-options?${queryString.stringify(params)}`
    );
    return toReturn;
}

/**
 * Customers
 */
export async function getCustomer(
    customerId,
    orgId,
    reqFromSalesOrderPage = false
) {
    return invokeApi(GET, `/private/${orgId}/customer/${customerId}`, {
        queryStringParameters: { reqFromSalesOrderPage }
    });
}

export async function getCustomerContracts(
    clientId,
    customerId,
    reqFromInvoicePage = false
) {
    return invokeApi(GET, `/private/${clientId}/customerContractss`, {
        queryStringParameters: { customerId, reqFromInvoicePage }
    });
}

export async function getCustomers(orgId, searchParams) {
    return invokeApi(GET, `/private/${orgId}/customers`, {
        queryStringParameters: searchParams
    });
}

/**
 * Errors
 */

export async function getErrors(orgId, searchParams) {
    return invokeApi(GET, `/private/${orgId}/errors`, {
        queryStringParameters: searchParams
    });
}

/**
 * ErrorCode
 */

export async function getErrorCode(errorCode) {
    return invokeApi(GET, `/private/errorCode`, {
        queryStringParameters: {
            errorCode
        }
    });
}

/**
 * ErrorInputSource
 */

export async function getErrorInputSource(orgId, searchParams) {
    return invokeApi(GET, `/private/${orgId}/errorInputSource`, {
        queryStringParameters: searchParams
    });
}

/**
 * ErrorInputSourceColumns
 */

export async function getErrorInputSourceColumns(orgId, searchParams) {
    return invokeApi(GET, `/private/${orgId}/errorInputSourceColumns`, {
        queryStringParameters: searchParams
    });
}

/**
 * Products
 */
export async function getProducts(orgId, queryStringParameters) {
    return invokeApi(GET, `/private/${orgId}/products`, {
        queryStringParameters
    });
}

export async function getProductGroups(orgId, queryStringParameters) {
    return invokeApi(GET, `/private/${orgId}/productGroups`, {
        queryStringParameters
    });
}

export async function getChildProducts(org, productId) {
    return invokeApi(
        GET,
        `/private/${org.id}/products/${Utils.encodeId(productId)}`
    );
}

export async function updateProducts(org, products) {
    return invokeApi(PUT, `/private/${org.id}/product`, {
        body: products
    });
}

/** Helper for performing sales order and export related filter calls. */
async function performSalesOrdersFilteredAction(params, url, method = GET) {
    const salesOrderSearchParams = Object.assign({}, params);

    return invokeApi(method, url, {
        queryStringParameters: salesOrderSearchParams
    });
}

/**
 * Export
 */
export async function getExportOptions(params) {
    return invokeApi(GET, `/private/${params.orgId}/export-options`);
}

export async function getExportCount(params) {
    return await performSalesOrdersFilteredAction(
        params,
        `/private/${params.orgId}/export/${Utils.encode(
            params.exportType
        )}/count`
    );
}

export async function downloadAllProducts(clientId) {
    return invokeApi(GET, `/private/${clientId}/product/download`);
}

export async function exportData(params) {
    return await performSalesOrdersFilteredAction(
        params,
        `/private/${params.orgId}/export/${Utils.encode(params.exportType)}`,
        POST
    );
}

export async function getExportedFiles(states, params) {
    return invokeApi(GET, `/private/${params.orgId}/exports`, {
        queryStringParameters: params
    });
}

export async function exportClientData(params) {
    return invokeApi(POST, `/private/${params.orgId}/exports`, {
        queryStringParameters: params
    });
}

/**
 * Import
 */
export async function importCustomFieldsData(orgId, params) {
    return invokeApi(POST, `/private/admin/${orgId}/populate-customfields`, {
        body: {
            ...params
        }
    });
}

/**
 * JournalAccountMappings
 */
export async function getJournalAccountMappings(orgId, params = {}) {
    return invokeApi(GET, `/private/${orgId}/journalAccountMappingss`, {
        queryStringParameters: params
    });
}

export async function updateJournalAccountMappings(orgId, body, params = {}) {
    return invokeApi(PUT, `/private/${orgId}/journalAccountMappings`, {
        body,
        queryStringParameters: params
    });
}

/**
 * Revenue Story
 */
export async function getAvailableRevenueStoryTypes(orgId) {
    return invokeApi(GET, `/private/${orgId}/revenuestorytypes`);
}

export async function getRevenueStory(orgId, params = {}) {
    return invokeApi(GET, `/private/${orgId}/revenuestory`, {
        queryStringParameters: params
    });
}

export async function exportRevenueStory(orgId, params = {}) {
    return invokeApi(POST, `/private/${orgId}/revenuestory/export`, {
        queryStringParameters: params
    });
}

/**
 * Reports (User's Favourite)
 */
export async function updateUserFavorites(orgId, username, reports = {}) {
    return invokeApi(PUT, `/private/${orgId}/user/favorites`, {
        body: {
            reports,
            username
        }
    });
}

export async function getUserFavorites(orgId, username) {
    return invokeApi(POST, `/private/${orgId}/user/favorites`, {
        body: {
            username
        }
    });
}

/**
 * Manual Adjustments
 */
export async function createAdjustment(orgId, params = {}) {
    return invokeApi(POST, `/private/${orgId}/adjustments`, {
        body: params
    });
}

export async function searchAdjustments(orgId, params = {}) {
    return invokeApi(GET, `/private/${orgId}/adjustments`, {
        queryStringParameters: params
    });
}

export async function getAdjustmentTypes(orgId) {
    return invokeApi(GET, `/private/${orgId}/adjustment-types`);
}

export async function deleteAdjustment(orgId, groupId) {
    return invokeApi(DELETE, `/private/${orgId}/adjustments/${groupId}`);
}

export async function updateAdjustment(orgId, params = {}, groupId) {
    return invokeApi(PUT, `/private/${orgId}/adjustments/${groupId}`, {
        body: params
    });
}

/**
 * SalesOrders
 */

export async function getSalesOrderUpdateReport(orgId, searchParams) {
    return invokeApi(GET, `/private/${orgId}/salesorders/report`, {
        queryStringParameters: searchParams
    });
}

export async function getOrderReportsUsers(clientId) {
    return invokeApi(GET, `/private/${clientId}/order/report/usernames`);
}

export async function getOrdersReportExportCount(clientId, queryStringParams) {
    return invokeApi(GET, `/private/${clientId}/order/report/export/count`, {
        queryStringParameters: queryStringParams
    });
}

export async function exportOrdersReportEvents(clientId, queryStringParams) {
    return invokeApi(POST, `/private/${clientId}/order/report/export`, {
        queryStringParameters: queryStringParams
    });
}

export async function getSalesOrders(orgId, params) {
    return await performSalesOrdersFilteredAction(
        params,
        `/private/${orgId}/salesOrders`
    );
}

export async function updateSalesOrder(state, clientId) {
    return invokeApi(PUT, `/private/${clientId}/salesOrder`);
}

export async function getSalesOrderById(clientId, salesOrderId) {
    return invokeApi(GET, `/private/${clientId}/salesOrder/${salesOrderId}`);
}

export async function getMerchant(merchantId) {
    return invokeApi(GET, `/merchants`, {
        queryStringParameters: {
            website: Utils.encodeId(merchantId, true)
        }
    });
}

export async function updateMerchant(params) {
    return invokeApi(PATCH, `/merchants`, {
        body: params
    });
}

export async function adminGetMerchant(merchantId) {
    return invokeApi(GET, `/private/admin/merchant?merchantId=${merchantId}`);
}

export async function adminAddMerchant(params) {
    return invokeApi(POST, `/private/admin/merchant`, {
        body: params
    });
}

export async function adminUpdateMerchant(params) {
    return invokeApi(PUT, `/private/admin/merchant`, {
        body: params
    });
}

/**
 * Organizations
 * {
 *  name : 'Name of the Tenant',
 *  domain: 'Merchant Name',
 *  linkBE: 'Linked / Associated Business Entity for SSO'
 * }
 */
export async function signUpClient({ name, domain, linkBE, license, trial }) {
    name = name || domain;
    //default to domain name for client if not available

    return invokeApi(POST, `/org`, {
        body: {
            name,
            domain,
            license,
            trial,
            linkBE
        }
    });
}

export async function companyExists({ website, tenant, query }) {
    return invokeApi(GET, `/tenants`, {
        queryStringParameters: { website, tenant, query }
    });
}

export async function signUpTenant(params) {
    return invokeApi(POST, `/tenants`, {
        body: params
    });
}

export async function createSystemTenants() {
    return invokeApi(POST, `/private/admin/system-tenants`);
}

export async function updateOrganizationAttribute(orgId, params) {
    return invokeApi(PATCH, `/private/${orgId}/org`, {
        body: params
    });
}

export async function updateOrganization(state, org, periodType) {
    return invokeApi(PUT, `/private/${org.id}/org`, {
        body: {
            org,
            periodType
        }
    });
}

export async function resyncWarehouse(clientId) {
    const path = `/private/${clientId}/org/warehouse/resync`;
    return invokeApi(POST, path, {});
}

export async function getStatus(clientId) {
    const path = `/private/${clientId}/status`;
    return invokeApi(GET, path, {});
}

export async function contactSales(clientId, type, name) {
    const path = `/private/${clientId}/org/license/inquiry`;

    return invokeApi(POST, path, {
        body: {
            type,
            name
        }
    });
}

export async function upgradeLicensing(clientId, type, name, trial) {
    const path = `/private/${clientId}/org/license/upgrade`;

    return invokeApi(POST, path, {
        body: {
            type,
            name,
            trial
        }
    });
}

export async function salesOrderExists(clientId, salesOrderId) {
    return invokeApi(
        GET,
        `/private/${clientId}/salesOrder/${Utils.encodeId(salesOrderId)}/exists`
    );
}

export async function salesOrdersExists(clientId) {
    return invokeApi(GET, `/private/${clientId}/salesOrder/exists`);
}

export async function revenueSummary(clientId) {
    return invokeApi(GET, `/private/${clientId}/revenueSummaryForDashboard`);
}

export async function endingDeferredRevenueByCustomer(clientId) {
    return invokeApi(
        GET,
        `/private/${clientId}/endingDeferredRevenueByCustomer`
    );
}

export async function upgradePc1toPc2(siteName) {
    const url = `/partners/upgrade/productCatalog?siteName=${siteName}`;
    return invokeApi(PUT, url, {
        body: {
            siteName
        }
    });
}

export async function isReSyncRequired(clientId) {
    return salesOrdersExists(clientId);
}

export async function inviteUserForOrganization(params) {
    const email = encodeURIComponent(params.username);
    const path = `/private/${params.clientId}/org/${email}`;
    return invokeApi(POST, path, {
        body: params
    });
}

export async function removeUserFromOrganization(state, params) {
    const email = encodeURIComponent(params.username);
    const path = `/private/${params.clientId}/org/${email}`;
    return invokeApi(DELETE, path, {
        body: params
    });
}

export async function updateOrganizationUser(state, params) {
    const email = encodeURIComponent(params.username);
    const path = `/private/${params.clientId}/org/${email}`;

    return invokeApi(PUT, path, {
        body: params
    });
}

export async function getOrganizationUsers(state, orgId) {
    return invokeApi(GET, `/private/${orgId}/users`);
}

export async function getWHBackups() {
    return invokeApi(GET, `/private/admin/whBackups`);
}

export async function restoreWH(database, s3Url, fileId) {
    return invokeApi(POST, `/private/admin/whRestore`, {
        body: {
            database,
            s3Url,
            fileId
        }
    });
}

export async function resetMarkedEnvironmentsForCleanup() {
    return invokeApi(POST, "/private/admin/orgs/expired");
}

export async function getMarkedEnvironmentsForCleanup(searchParams) {
    return invokeApi(GET, "/private/admin/orgs/expired", {
        queryStringParameters: searchParams
    });
}

export async function cleanUpEnvironments(params) {
    return invokeApi(DELETE, "/private/admin/orgs", {
        body: params
    });
}

export async function getAllOrganizations(
    start = 0,
    limit = 10,
    search = "",
    lookupStats = false
) {
    return invokeApi(
        GET,
        `/private/admin/orgs?start=${start}&limit=${limit}&search=${search}&lookupStats=${lookupStats}`
    );
}

export async function upgradeOrganization(
    tenantIds,
    versionTo,
    allTenants,
    numTenantsToUpgrade,
    jobWeight
) {
    return invokeApi(POST, `/private/admin/upgrade`, {
        body: {
            tenantIds,
            versionTo,
            allTenants,
            numTenantsToUpgrade,
            jobWeight
        }
    });
}

export async function deleteOrganization(orgId, cbsso = false) {
    return invokeApi(DELETE, `/private/admin/${orgId}/org`, {
        body: {
            cbsso
        }
    });
}

export async function initiateRecon(runForIgnoredTenants) {
    return invokeApi(POST, `/private/admin/orgs/recon`, {
        body: { runForIgnoredTenants }
    });
}

export async function invokeTestChargebeeApi(siteId) {
    return invokeApi(POST, `/private/admin/test-chargebee-internal-key`, {
        body: { siteId }
    });
}

export async function spawnContinuousExportJob() {
    return invokeApi(POST, `/private/admin/continuousExportJob`);
}

export async function initiateProdStats(params) {
    return invokeApi(POST, `/private/admin/orgs/prodStats`, {
        body: params
    });
}

export async function invokeRegression({ scenario, branch, email }) {
    return invokeApi(POST, `/private/admin/run-regression`, {
        body: { scenario, email, branch }
    });
}

export async function recordJobs({
    clientId,
    scenario,
    firstPeriod,
    branch,
    recordAllJournals
}) {
    return invokeApi(POST, `/private/admin/recordJob`, {
        body: { clientId, scenario, firstPeriod, branch, recordAllJournals }
    });
}

export async function invokeHGRollbackJob({ s3filename, logOnly }) {
    return invokeApi(POST, `/private/admin/hgrollbackJob`, {
        body: { s3filename, logOnly }
    });
}

export async function generateJournalEntries(clientId, startPeriod, endPeriod) {
    const url = `/private/${clientId}/journal-entries`;

    return invokeApi(POST, url, {
        body: {
            startPeriod,
            endPeriod
        }
    });
}

export async function setAccountingPeriod(clientId, period) {
    const url = `/private/${clientId}/org/period/accounting`;
    return invokeApi(PUT, url, {
        body: {
            period
        }
    });
}

export async function setReportingPeriod(clientId, startPeriodsAgo) {
    const url = `/private/${clientId}/org/period/reporting`;
    return invokeApi(PUT, url, {
        body: {
            startPeriodsAgo
        }
    });
}

export async function updateHomeCurrency(clientId, currency) {
    const url = `/private/${clientId}/organization/currency`;
    return await invokeApi(PUT, url, {
        body: {
            currency
        }
    });
}

export async function clearSSPRules(clientId) {
    return invokeApi(DELETE, `/private/${clientId}/standaloneSellingPrices`);
}

export async function getPriceAnalysis(clientId) {
    return invokeApi(GET, `/private/${clientId}/priceAnalysis`);
}

export async function getSSPAnalysis(clientId, product) {
    return invokeApi(GET, `/private/${clientId}/sspAnalysis/${product}`);
}

export async function runSSPAnalyzerJob(clientId) {
    return invokeApi(POST, `/private/${clientId}/job/sspAnalyzer`);
}

export async function updateSSPFields(clientId, config) {
    return invokeApi(PUT, `/private/${clientId}/orgConfig/sspFields`, {
        body: {
            config
        }
    });
}

export async function updateJournalMappingFields(clientId, config) {
    return invokeApi(
        PUT,
        `/private/${clientId}/orgConfig/journalMappingFields`,
        {
            body: {
                config
            }
        }
    );
}

export async function republishAllSalesOrders(clientId) {
    return invokeApi(POST, `/private/${clientId}/republish`, {
        body: {}
    });
}

export async function validChargebeeToken(uiSession) {
    return invokeApi(
        POST,
        `/private/user/refresh-chargebee-token`,
        {
            body: {
                uiSession: uiSession
            }
        },
        PRIVATE_API_NAME
    );
}

export async function getUser() {
    let url = `/private/user`;
    return await invokeApi(GET, url, null, PRIVATE_API_NAME);
}

export async function getAuthorizations() {
    let url = `/private/authorizations`;
    return await invokeApi(GET, url, null, PRIVATE_API_NAME);
}

export async function getImpersonatedUser(username) {
    let url = `/private/admin/user/${username}`;

    return await invokeApi(GET, url, null, PRIVATE_API_NAME);
}

export async function addSuperUserToAccount(clientId, body = {}) {
    const url = `/private/admin/${clientId}/user`;
    return await invokeApi(POST, url, { body: body }, PRIVATE_API_NAME);
}

export async function overrideAutoSync(clientId, salesOrderId, syncDisabled) {
    const url = `/private/${clientId}/salesOrder/${Utils.encodeId(
        salesOrderId
    )}/override-autosync`;
    return await invokeApi(
        POST,
        url,
        { body: { syncDisabled } },
        PRIVATE_API_NAME
    );
}

export async function getOveragePS(clientId, customerId, salesOrderId) {
    const url = `/private/${clientId}/service-delivery/${customerId}/overage`;
    return await invokeApi(
        GET,
        url,
        { queryStringParameters: { salesOrderId } },
        PRIVATE_API_NAME
    );
}

export async function rerunRollForward({
    clientId,
    s3filename,
    forceRF,
    action
}) {
    const url = `/private/admin/${clientId}/rollforwardTools/${action}`;
    return await invokeApi(
        POST,
        url,
        { body: { s3filename, forceRF } },
        PRIVATE_API_NAME
    );
}

export async function salesOrdersUtils({
    clientId,
    s3filename,
    allOrders,
    action
}) {
    const url = `/private/admin/${clientId}/salesOrder/${action}`;
    return await invokeApi(
        POST,
        url,
        { body: { s3filename, allOrders } },
        PRIVATE_API_NAME
    );
}

export async function invokeArchive(clientId, salesOrderIds) {
    const url = `/private/admin/${clientId}/archive`;
    return await invokeApi(
        POST,
        url,
        { body: { salesOrderIds } },
        PRIVATE_API_NAME
    );
}

export async function invokeMigrateMerchant(
    clientId,
    fromMerchantId,
    toMerchantId
) {
    const url = `/private/admin/${clientId}/migrate-tenant`;
    return await invokeApi(
        POST,
        url,
        { body: { fromMerchantId, toMerchantId } },
        PRIVATE_API_NAME
    );
}

export async function invokeEnableRevrecNac(
    clientId,
    chargebeeSite,
    enableRevrecNav
) {
    const url = `/private/admin/${clientId}/enable-revrec-nav`;
    return await invokeApi(
        POST,
        url,
        { body: { chargebeeSite, enableRevrecNav } },
        PRIVATE_API_NAME
    );
}

export async function invokeClone(
    clientId,
    salesOrderIds = [],
    defaultUserEmail = null
) {
    const url = `/private/admin/${clientId}/clone`;
    return await invokeApi(
        POST,
        url,
        { body: { salesOrderIds, defaultUserEmail } },
        PRIVATE_API_NAME
    );
}

export async function invokeCloneConfig(clientId, destClientId) {
    const url = `/private/admin/${clientId}/cloneConfig`;
    return await invokeApi(
        POST,
        url,
        { body: { srcClientId: clientId, destClientId } },
        PRIVATE_API_NAME
    );
}

export async function invokePublishConfig(clientId) {
    const url = `/private/admin/${clientId}/publishConfig`;
    return await invokeApi(POST, url, {}, PRIVATE_API_NAME);
}

export async function invokeMissingRA(clientId, jobId) {
    const url = `/private/admin/${clientId}/missingRA`;
    return await invokeApi(
        POST,
        url,
        { body: { clientId, jobId } },
        PRIVATE_API_NAME
    );
}

export async function invokeRecon(clientId, params) {
    const url = `/private/admin/${clientId}/reconOrg`;
    return await invokeApi(POST, url, { body: params }, PRIVATE_API_NAME);
}

export async function clearRecon(clientId) {
    const url = `/private/admin/${clientId}/clearReconErrors`;
    return await invokeApi(DELETE, url, {}, PRIVATE_API_NAME);
}

export async function getClones(clientId) {
    const url = `/private/admin/${clientId}/clone`;
    return await invokeApi(GET, url, {}, PRIVATE_API_NAME);
}

export async function getMigrationStatus(clientId) {
    const url = `/private/admin/${clientId}/migrate`;
    return await invokeApi(GET, url, {}, PRIVATE_API_NAME);
}

export async function getGlobalExportableViews() {
    return await invokeApi(
        GET,
        `/private/admin/global-exportable-views`,
        {},
        PRIVATE_API_NAME
    );
}

export async function invokeMigration(clientId, database, deleteSrcData) {
    const url = `/private/admin/${clientId}/migrate`;
    return await invokeApi(
        POST,
        url,
        {
            queryStringParameters: { database, deleteSrcData }
        },
        PRIVATE_API_NAME
    );
}

export async function invokeMigrationCleanup(clientId) {
    return await invokeApi(
        POST,
        `/private/admin/${clientId}/migration-cleanup`
    );
}

export async function restoreSnapshot(clientId, snapshot, environment) {
    const url = `/private/admin/${clientId}/restore`;
    return await invokeApi(
        POST,
        url,
        {
            body: {
                snapshot,
                environment
            }
        },
        PRIVATE_API_NAME
    );
}

export async function getUserOrganizations(withUsers = false) {
    let url = `/private/orgs`;
    if (withUsers) url = url + "?users=true";
    return invokeApi(GET, url);
}

export async function getOrgImages(state, orgs) {
    if (orgs) {
        let promisesOrgImages = orgs.map((org) => {
            return Storage.get(org.id + ".image", { level: "public" });
        });

        let imagesResult = await Promise.all(promisesOrgImages);
        imagesResult = imagesResult.filter((element) => element != null);

        orgs = orgs.map((org, index) => {
            org.image = imagesResult[index];
            return org;
        });

        // console.log("Successfully fetched user organization " + JSON.stringify(results));
        return orgs;
    }
}

export async function updateOrganizationImage(state, params) {
    let result = undefined;
    const isTestOrg = AccountingUtils.isTestId(params.orgId);
    const secondaryOrgId = isTestOrg
        ? AccountingUtils.toProdId(params.orgId)
        : AccountingUtils.toTestId(params.orgId);
    const orgIds = [params.orgId, secondaryOrgId];
    let promiseImages = orgIds.map((org) =>
        Storage.put(org + ".image", params.imgFile, {
            level: "public",
            contentType: "image/png"
        })
    );
    let results = await Promise.all(promiseImages);
    results = results.filter((element) => element != null);
    return Promise.resolve(results);
}

export async function updateCustomFieldTranslation(
    clientId,
    clientName,
    mstrProjectName,
    mstrLanguageName,
    isBillingMapping,
    newCustomFieldsMapping,
    originalCustomFieldsMapping
) {
    const mstrGroupName = getMicroStrategyGroupName(clientId, clientName);

    return invokeApi(
        POST,
        `/private/${clientId}/microstrategy/updatecustomfield`,
        {
            body: {
                mstrProjectName,
                mstrLanguageName,
                mstrGroupName,
                isBillingMapping,
                newCustomFieldsMapping,
                originalCustomFieldsMapping
            }
        }
    );
}

/**
 * Sync Jobs
 */
export async function triggerSync(state, params) {
    return invokeApi(POST, `/private/${params.orgId}/triggerSync`, {
        body: { syncParams: { s3filename: params.filename } }
    });
}

export async function archiveSync(orgId, jobId) {
    return invokeApi(PATCH, `/private/${orgId}/sync/${jobId}/archive`);
}

export async function archiveSyncJobGroup(orgId, jobId) {
    return invokeApi(
        PATCH,
        `/private/${orgId}/sync/${jobId}/archive-job-group`
    );
}

export async function getJobJson(orgId, jobId) {
    return invokeApi(GET, `/private/${orgId}/sync/${jobId}/json`);
}

export async function getJobGroupJson(orgId, jobGroupId) {
    return invokeApi(GET, `/private/${orgId}/sync/${jobGroupId}/json-group`);
}

export async function getSyncsStatus(orgId, queryStringParams) {
    return invokeApi(GET, `/private/${orgId}/sync/history`, {
        queryStringParameters: queryStringParams
    });
}

export async function getActiveSyncJobs(orgId) {
    return invokeApi(GET, `/private/${orgId}/sync/recent`);
}

export async function getSyncStatus(state, params) {
    return invokeApi(GET, `/private/${params.orgId}/syncStatus`, {
        queryStringParameters: { jobId: params.jobId }
    });
}

export async function getTaskSummary(state, params) {
    return invokeApi(GET, `/private/${params.orgId}/tasksSummary`);
}

export async function getCompanyId({ website, query }) {
    return invokeApi(
        GET,
        `/company`,
        {
            queryStringParameters: { website, query }
        },
        PUBLIC_API_NAME
    );
}

export async function deleteEnvironment(orgId, cbsso = false) {
    return invokeApi(DELETE, `/private/${orgId}/environment`, {
        body: {
            cbsso
        }
    });
}

export async function addTestTransactions(orgId, dataSet) {
    return invokeApi(POST, `/private/${orgId}/environment/demo`, {
        dataSet
    });
}

export async function getHotglueSecureToken(orgId) {
    return invokeApi(GET, `/private/${orgId}/hotglue/secureToken`);
}

export async function hotglueAccountingLink(orgId, body) {
    return invokeApi(POST, `/private/${orgId}/hotglue/accounting-linked`, {
        body
    });
}

export async function hotglueAccountingUnLink(orgId, body) {
    return invokeApi(DELETE, `/private/${orgId}/hotglue/accounting-unlinked`, {
        body
    });
}

export async function getForeignExchangeRates(orgId) {
    return invokeApi(GET, `/private/${orgId}/hotglue/foreignExchangeRates`);
}

export async function hotglueLinkFlow(orgId, body) {
    return invokeApi(POST, `/private/${orgId}/hotglue/link-flow`, {
        body
    });
}

export async function hotglueUnLinkFlow(orgId, body) {
    return invokeApi(DELETE, `/private/${orgId}/hotglue/unlink-flow`, {
        body
    });
}

export async function getSiteAccessKey(partner, siteKey, siteAccessKey) {
    const url = `/partners/sites/exists?partner=${partner}&siteKey=${siteKey}&siteAccessKey=${siteAccessKey}`;
    return invokeApi(GET, url);
}

export async function getPricingSummary(orgId, range) {
    const baseUrl = `/private/${orgId}/pricingSummary`;
    const url = range ? `${baseUrl}?range=${range}` : baseUrl;
    return invokeApi(GET, url);
}

export async function restoreSSPVersion(clientId, versionId) {
    return invokeApi(
        PUT,
        `/private/${clientId}/standaloneSellingPrice/version/${versionId}`
    );
}

export async function getSSPByVersionId(clientId, versionId) {
    return invokeApi(
        GET,
        `/private/${clientId}/standaloneSellingPrice/version/${versionId}`
    );
}

export async function getSSPVersions(clientId) {
    return invokeApi(
        GET,
        `/private/${clientId}/standaloneSellingPrice/versions`
    );
}

export async function getVersions(clientId, salesOrderId) {
    return invokeApi(
        GET,
        `/private/${clientId}/salesOrder/${Utils.encodeId(
            salesOrderId
        )}/versions`
    );
}

export async function restoreVersion(clientId, snapshotId, syncDisabled) {
    return invokeApi(
        POST,
        `/private/${clientId}/salesOrder/version/${snapshotId}`,
        {
            body: {
                syncDisabled
            }
        }
    );
}

export async function getSyncLockStatus(clientId) {
    return invokeApi(GET, `/private/${clientId}/lock`);
}

export async function unlockSyncLock(clientId) {
    return invokeApi(DELETE, `/private/${clientId}/lock`);
}

export async function downloadJobErrors(clientId) {
    return invokeApi(GET, `/private/admin/${clientId}/joberrors`);
}

export async function downloadDisabledSalesOrders(clientId) {
    return invokeApi(GET, `/private/${clientId}/disabledsalesorders`);
}

export async function overrideSyncForOrders(clientId, s3filename, action) {
    return invokeApi(
        POST,
        `/private/${clientId}/salesorders/syncoverride/${action}`,
        {
            body: { s3filename }
        }
    );
}

export async function uploadIgnoreAndDeleteJobErrors(clientId, s3filename) {
    return invokeApi(POST, `/private/admin/${clientId}/joberrors`, {
        body: { s3filename }
    });
}

export async function downloadMissingSalesOrders(clientId) {
    return invokeApi(GET, `/private/admin/${clientId}/missingsalesorders`);
}

export async function getRevenueStartDate(clientId) {
    return invokeApi(GET, `/private/admin/${clientId}/revenuestartdate`);
}

export async function addAuditChooseDomain() {
    return invokeApi(POST, `/private/user/audit-choose-a-domain`, {});
}

export async function addAuditEvent(clientId, queryStringParams) {
    return invokeApi(POST, `/private/${clientId}/audit`, {
        queryStringParameters: queryStringParams
    });
}

export async function getAuditTrailUsers(clientId) {
    return invokeApi(GET, `/private/${clientId}/audit/usernames`);
}

export async function getAuditTrailEventTypes(clientId) {
    return invokeApi(GET, `/private/${clientId}/audit/types`);
}

export async function getAuditTrailEntityTypes(clientId) {
    return invokeApi(GET, `/private/${clientId}/audit/entities`);
}

export async function getAuditTrailEvents(clientId, queryStringParams) {
    return invokeApi(GET, `/private/${clientId}/audit/events`, {
        queryStringParameters: queryStringParams
    });
}

export async function getAuditTrailExportCount(clientId, queryStringParams) {
    return invokeApi(GET, `/private/${clientId}/audit/export/count`, {
        queryStringParameters: queryStringParams
    });
}

export async function exportAuditTrailEvents(clientId, queryStringParams) {
    return invokeApi(POST, `/private/${clientId}/audit/export`, {
        queryStringParameters: queryStringParams
    });
}

export async function getSecurityUsers(clientId) {
    return invokeApi(GET, `/private/${clientId}/audit/security-users`);
}

export async function getSecurityEvents(clientId, queryStringParams) {
    return invokeApi(GET, `/private/${clientId}/audit/security-events`, {
        queryStringParameters: queryStringParams
    });
}

export async function executeGovernor() {
    return invokeApi(POST, `/private/admin/executeGovernor`, {});
}

export async function getProcesses(queryStringParams) {
    return invokeApi(GET, `/private/admin/processes`, {
        queryStringParameters: queryStringParams
    });
}

export async function cancelProcesses(clientId, jobGroupId) {
    return invokeApi(
        DELETE,
        `/private/admin/${clientId}/processes/${jobGroupId}`
    );
}

export async function markJobPriority(clientId, jobGroupId, priority) {
    return invokeApi(
        PUT,
        `/private/admin/${clientId}/processes/${jobGroupId}/priority/${priority}`
    );
}

export async function runProcessNow(clientId, jobGroupId) {
    return invokeApi(
        PUT,
        `/private/admin/${clientId}/processes/${jobGroupId}/signal`
    );
}

export async function generateGlobalExport(queryStringParams) {
    return invokeApi(POST, `/private/admin/global-exports`, {
        queryStringParameters: queryStringParams
    });
}

/**
 * Accounting Central
 */
function getBusinessEntityParam(org) {
    if (org && org.be_id) {
        return `?be_id=${org.be_id}`;
    }

    return "";
}

function addBusinessEntityParam(org, params = {}) {
    if (org && org.be_id) {
        params.be_id = org.be_id;
    }

    return params;
}

export async function getACErrorCodes() {
    return invokeApi(GET, `/private/ac/errorcodes`);
}

export async function getBusinessEntities(clientId) {
    return invokeApi(GET, `/private/${clientId}/ac/business_entities`);
}

export async function getACGridMetaData(org, parameters) {
    // Query string params can be:
    // table or query (one of these required),
    // sourceEntity, columnDefaults, ignoreColumns, showCustomFieldsByDefault.
    if (parameters) {
        for (const key of Object.keys(parameters)) {
            if (
                parameters[key] !== undefined &&
                (Array.isArray(parameters[key]) ||
                    parameters[key].constructor == {}.constructor)
            ) {
                parameters[key] = JSON.stringify(parameters[key]);
            }
        }
    }

    return invokeApi(GET, `/private/${org.id}/ac/meta-data`, {
        queryStringParameters: addBusinessEntityParam(org, parameters)
    });
}

/** AC Accounts */
export async function getACInfo(org) {
    return invokeApi(GET, `/private/${org.id}/ac/info`, {
        queryStringParameters: addBusinessEntityParam(org)
    });
}

export async function getACAccountingEvents(clientId) {
    return invokeApi(GET, `/private/${clientId}/ac/accounting-events`);
}

export async function getACAccounts(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/accounts`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function addACAccount(org, values) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/accounts${getBusinessEntityParam(org)}`,
        {
            body: {
                ...values
            }
        }
    );
}

export async function updateACAccount(org, accountNumber, updates) {
    return invokeApi(
        PUT,
        `/private/${org.id}/ac/accounts${getBusinessEntityParam(org)}`,
        {
            body: {
                accountNumber,
                updates
            }
        }
    );
}

export async function deleteACAccount(org, accountNumber) {
    return invokeApi(
        DELETE,
        `/private/${org.id}/ac/accounts${getBusinessEntityParam(org)}`,
        {
            body: {
                accountNumber
            }
        }
    );
}

export async function deleteAllACAccounts(org) {
    return invokeApi(
        DELETE,
        `/private/${clientId}/ac/accounts${getBusinessEntityParam(org)}`,
        {
            body: {
                deleteAll: true
            }
        }
    );
}

export async function addAllDefaultACAccounts(org) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/accounts/default${getBusinessEntityParam(org)}`
    );
}

/** AC JAMs */
export async function getACAccountingEventTypes(clientId) {
    return invokeApi(GET, `/private/${clientId}/ac/accounting-event-types`);
}

export async function getACJournalAccountMappings(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/jams`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function addACJournalAccountMapping(
    org,
    accountingEventType,
    groupName,
    values
) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/jams${getBusinessEntityParam(org)}`,
        {
            body: {
                accountingEventType,
                groupName,
                values
            }
        }
    );
}

export async function updateACJournalAccountMapping(
    org,
    accountingEventType,
    groupName,
    keys,
    updates
) {
    return invokeApi(
        PUT,
        `/private/${org.id}/ac/jams${getBusinessEntityParam(org)}`,
        {
            body: {
                accountingEventType,
                groupName,
                keys,
                updates
            }
        }
    );
}

export async function deleteACJournalAccountMapping(
    org,
    accountingEventType,
    groupName,
    values
) {
    return invokeApi(
        DELETE,
        `/private/${org.id}/ac/jams${getBusinessEntityParam(org)}`,
        {
            body: {
                accountingEventType,
                groupName,
                values
            }
        }
    );
}

export async function addAllACJournalAccountMappings(
    org,
    accountingEventType,
    groupName
) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/jams${getBusinessEntityParam(org)}`,
        {
            body: {
                accountingEventType,
                groupName,
                addMissing: true
            }
        }
    );
}

export async function deleteAllACJournalAccountMappings(
    org,
    accountingEventType,
    groupName
) {
    return invokeApi(
        DELETE,
        `/private/${org.id}/ac/jams${getBusinessEntityParam(org)}`,
        {
            body: {
                accountingEventType,
                groupName,
                deleteAll: true
            }
        }
    );
}

/** AC Customers */
export async function getACCustomers(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/customers`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function getACCustomer(clientId, customerId) {
    return invokeApi(GET, `/private/${clientId}/ac/customers/${customerId}`);
}

export async function getACCustomerBalances(clientId, customerId, period) {
    return invokeApi(
        GET,
        `/private/${clientId}/ac/customers/${customerId}/balances`,
        {
            queryStringParameters: {
                period
            }
        }
    );
}

export async function getACEvents(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/events`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function getACSubscriptions(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/subscriptions`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function getACPeriodBalances(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/ac/periodbalances`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function getACBalances(org, period, types) {
    return invokeApi(GET, `/private/${org.id}/ac/balances`, {
        queryStringParameters: addBusinessEntityParam(org, {
            period,
            types: types ? JSON.stringify(types) : undefined
        })
    });
}

/** AC Close/Reopen APIs */
export async function getACClosedPeriod(clientId) {
    return invokeApi(GET, `/private/${clientId}/ac/closedperiod`);
}

export async function getACClosingRecords(org) {
    return invokeApi(GET, `/private/${org.id}/ac/closingrecords`, {
        queryStringParameters: addBusinessEntityParam(org)
    });
}

export async function reopenClosingRecords(org, recordsToReopen) {
    return invokeApi(
        DELETE,
        `/private/${org.id}/ac/closingrecords${getBusinessEntityParam(org)}`,
        {
            body: recordsToReopen
        }
    );
}

export async function getAccountingEventTypesToClose(org) {
    return invokeApi(GET, `/private/${org.id}/ac/closedrecords`, {
        queryStringParameters: addBusinessEntityParam(org)
    });
}

export async function closeACAccountingPeriod(
    org,
    period,
    closingRecord,
    postEntries = false
) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/${period}/closeperiod${getBusinessEntityParam(
            org
        )}`,
        {
            body: { postEntries, closingRecord }
        }
    );
}

export async function areACJournalsOutdated(org) {
    return invokeApi(GET, `/private/${org.id}/ac/arejournalsoutdated`, {
        queryStringParameters: addBusinessEntityParam(org)
    });
}

export async function exportACJournals(org, requestBody) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/exportjournals${getBusinessEntityParam(org)}`,
        {
            body: requestBody
        }
    );
}

export async function deleteACJournals(org, requestBody) {
    return invokeApi(
        DELETE,
        `/private/${org.id}/ac/journals${getBusinessEntityParam(org)}`,
        {
            body: requestBody
        }
    );
}

export async function isACJournalPostingOutdated(org) {
    return invokeApi(GET, `/private/${org.id}/ac/isjournalpostingoutdated`, {
        queryStringParameters: addBusinessEntityParam(org)
    });
}

export async function generateACJournals(org) {
    return invokeApi(
        POST,
        `/private/${org.id}/ac/generatejournals${getBusinessEntityParam(org)}`
    );
}

export async function hotglueAccountingACLink(orgId, body) {
    return invokeApi(POST, `/private/${orgId}/ac/hotglue/accounting-linked`, {
        body
    });
}

export async function hotglueAccountingACUnLink(orgId, body) {
    return invokeApi(
        DELETE,
        `/private/${orgId}/ac/hotglue/accounting-unlinked`,
        {
            body
        }
    );
}

/** Smoke Test APIs */
export async function getSmokeTestStatus() {
    return invokeApi(GET, `/private/admin/smoke-status`);
}

/** Beta Recon APIs */
export async function getBetaReconConfiguration() {
    return invokeApi(GET, `/private/admin/beta-recon`);
}

export async function updateBetaReconConfiguration(configuration) {
    return invokeApi(PUT, `/private/admin/beta-recon`, {
        body: configuration
    });
}

export async function enableBetaRecon() {
    return invokeApi(PUT, `/private/admin/beta-recon/enable`);
}

export async function disableBetaRecon() {
    return invokeApi(PUT, `/private/admin/beta-recon/disable`);
}

export async function runBetaRecon() {
    return invokeApi(PUT, `/private/admin/beta-recon/run`);
}

export async function getNamedQueries(queryStringParameters) {
    return invokeApi(GET, `/private/admin/named-queries`, {
        queryStringParameters
    });
}

export async function updateNamedQuery(namedQuery, updatedNamedQuery) {
    return invokeApi(PUT, `/private/admin/named-queries`, {
        body: {
            namedQuery,
            updatedNamedQuery
        }
    });
}

export async function addNamedQuery(namedQuery) {
    return invokeApi(POST, `/private/admin/named-queries`, {
        body: namedQuery
    });
}

export async function deleteNamedQuery(namedQueries) {
    const deleteKeys = namedQueries.map((q) => {
        return {
            orgId: q.orgId,
            name: q.name
        };
    });
    return invokeApi(DELETE, `/private/admin/named-queries`, {
        body: deleteKeys
    });
}

// Returns the report tabs that needs to show up on UI
export async function getNamedQueryReportTabs(clientId) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports/report-tabs`
    );
}

// Returns the report names that needs to show up under report tab.
export async function getNamedQueryReports(clientId, queryStringParameters) {
    return invokeApi(GET, `/private/${clientId}/named-query-reports`, {
        queryStringParameters
    });
}

export async function getReportMetaData(org, queryName, params = {}) {
    return invokeApi(GET, `/private/${org.id}/named-query-reports/meta-data`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            Object.assign(
                {
                    queryName
                },
                params
            )
        )
    });
}

export async function getNamedQueryReportMetaData(clientId, queryName) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports/meta-data`,
        {
            queryStringParameters: {
                queryName
            }
        }
    );
}

export async function saveNamedQueryMetaData(clientId, body) {
    return invokeApi(
        POST,
        `/private/${clientId}/named-query-reports/meta-data/save`,
        { body }
    );
}

// Returns the report data (Results upon click of the report name).
export async function getReportResults(org, queryStringParameters) {
    return invokeApi(GET, `/private/${org.id}/named-query-reports/results`, {
        queryStringParameters: addBusinessEntityParam(
            org,
            queryStringParameters
        )
    });
}

export async function getNamedQueryReportResults(
    clientId,
    queryStringParameters
) {
    return invokeApi(GET, `/private/${clientId}/named-query-reports/results`, {
        queryStringParameters
    });
}

// Count for pagination on report
export async function getNamedQueryReportCount(
    clientId,
    queryStringParameters
) {
    return invokeApi(GET, `/private/${clientId}/named-query-reports/count`, {
        queryStringParameters
    });
}

// Export (download) the report
export async function exportNamedQueryReport(clientId, exportParameters) {
    return invokeApi(POST, `/private/${clientId}/named-query-reports/export`, {
        body: exportParameters
    });
}

// Returns the report names that needs to show up under report tab.
export async function getNamedQueryReportByName(clientId, namespace, name) {
    return await invokeApi(
        GET,
        `/private/${clientId}/named-query-reports?namespace=${namespace}&queryName=${name}`
    );
}

// Returns the report tabs that needs to show up on UI
export async function getACNamedQueryReportTabs(clientId) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports/report-tabs?namespace=ac`
    );
}

// Returns the report names that needs to show up under report tab.
export async function getACNamedQueryReports(clientId, queryStringParameters) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports?namespace=ac`,
        {
            queryStringParameters
        }
    );
}

// Returns the report data (Results upon click of the report name).
export async function getACNamedQueryReportResults(
    clientId,
    queryStringParameters
) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports/results?namespace=ac`,
        {
            queryStringParameters
        }
    );
}

// Count for pagination on report
export async function getACNamedQueryReportCount(
    clientId,
    queryStringParameters
) {
    return invokeApi(
        GET,
        `/private/${clientId}/named-query-reports/count?namespace=ac`,
        {
            queryStringParameters
        }
    );
}

// Export (download) the report
export async function exportACNamedQueryReport(clientId, exportParameters) {
    return invokeApi(POST, `/private/${clientId}/named-query-reports/export`, {
        body: exportParameters
    });
}

// Product Service Admin Action APIs

export async function getProductServiceUser(body) {
    return invokeApi(GET, `/private/admin/product-service/product-user`, {
        queryStringParameters: body
    });
}

export async function addProductServiceUser(body) {
    return invokeApi(POST, `/private/admin/product-service/product-user`, {
        body: body
    });
}

export async function deleteProductServiceUser(body) {
    return invokeApi(DELETE, `/private/admin/product-service/product-user`, {
        queryStringParameters: body
    });
}

export async function addProductServiceBusinessEntity(body) {
    return invokeApi(POST, `/private/admin/product-service/business-entity`, {
        body: body
    });
}

export async function getProductServiceBusinessEntity(body) {
    return invokeApi(GET, `/private/admin/product-service/business-entity`, {
        queryStringParameters: body
    });
}

export async function deleteProductServiceBusinessEntity(body) {
    return invokeApi(DELETE, `/private/admin/product-service/business-entity`, {
        queryStringParameters: body
    });
}

export async function getProductServiceBEUser(body) {
    return invokeApi(
        GET,
        `/private/admin/product-service/business-entity-user`,
        {
            queryStringParameters: body
        }
    );
}

export async function addProductServiceBEUser(body) {
    return invokeApi(
        POST,
        `/private/admin/product-service/business-entity-user`,
        {
            body: body
        }
    );
}

export async function deleteProductServiceBEUser(body) {
    return invokeApi(
        DELETE,
        `/private/admin/product-service/business-entity-user`,
        {
            queryStringParameters: body
        }
    );
}

export async function getProductServiceSourceBEMapping(body) {
    return invokeApi(
        GET,
        `/private/admin/product-service/business-entity-mapping/source`,
        {
            queryStringParameters: body
        }
    );
}

export async function getProductServiceDestinationBEMapping(body) {
    return invokeApi(
        GET,
        `/private/admin/product-service/business-entity-mapping/destination`,
        {
            queryStringParameters: body
        }
    );
}

export async function addProductServiceDestinationBEMapping(body) {
    return invokeApi(
        POST,
        `/private/admin/product-service/business-entity-mapping`,
        {
            body: body
        }
    );
}

export async function deleteProductServiceDestinationBEMapping(body) {
    return invokeApi(
        DELETE,
        `/private/admin/product-service/business-entity-mapping`,
        {
            queryStringParameters: body
        }
    );
}

export async function migrateToProductService(body) {
    return invokeApi(
        POST,
        `/private/admin/product-service/migrate-to-product-service`,
        {
            body: body
        }
    );
}

export async function removeMigrationToProductService(body) {
    return invokeApi(
        DELETE,
        `/private/admin/product-service/remove-from-product-service`,
        {
            queryStringParameters: body
        }
    );
}

export async function isProductServiceDisabled(clientId) {
    return invokeApi(
        GET,
        `/private/admin/product-service/${clientId}/disable-product-service`
    );
}

export async function enableProductService(clientId) {
    return invokeApi(
        DELETE,
        `/private/admin/product-service/${clientId}/disable-product-service`
    );
}

export async function disableProductService(clientId) {
    return invokeApi(
        POST,
        `/private/admin/product-service/${clientId}/disable-product-service`
    );
}

export const GET = async (apiName, path, init) => API.get(apiName, path, init);
export const POST = async (apiName, path, init) =>
    API.post(apiName, path, init);
export const PUT = async (apiName, path, init) => API.put(apiName, path, init);
export const PATCH = async (apiName, path, init) =>
    API.patch(apiName, path, init);
export const DELETE = async (apiName, path, init) =>
    API.del(apiName, path, init);
export const HEAD = async (apiName, path, init) =>
    API.head(apiName, path, init);

export function setCookie(cName, cValue, path, minutes) {
    let expires = "";
    if (minutes) {
        let d = new Date();
        d.setTime(d.getTime() + minutes * 60 * 1000);
        expires = "; expires=" + d.toUTCString();
    }
    document.cookie = cName + "=" + cValue + expires + ";path=" + path;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts
            .pop()
            .split(";")
            .shift();
    }
}

export async function invokeApi(
    method,
    path,
    myInit,
    apiName = PRIVATE_API_NAME,
    processError = true
) {
    let simulatedProfile;

    try {
        myInit = myInit || {};
        myInit.response = true;

        myInit.headers = myInit.headers || {};

        //if you are localhost track which profile you are
        //being simulated to be on using a cookie and also send this
        //to the server so it also knows you have switched

        if (window.location.hostname === "localhost") {
            simulatedProfile = getCookie("simulatedLocalRedirectProfile");
            if (simulatedProfile) {
                myInit.headers["x-profile"] = simulatedProfile;
            }
        }

        const isPublicApi = apiName === PUBLIC_API_NAME;
        if (!isPublicApi && customAuthorizationEnabled) {
            try {
                let session = await Auth.currentSession();
                myInit.headers[
                    "Authorization"
                ] = session.getIdToken().getJwtToken();
            } catch (e) {
                console.error(
                    "Unable to obtain a valid session and JWT token!!",
                    e
                );
                throw Error("User is not authenticated");
            }
        }

        let response = await method(apiName, path, myInit);

        // So this is a workaround that will download the response from S3 instead of response from API.
        // This is done to work around limitation in Lambda that limits the response to 6MB.
        if (response && response.data && response.data.s3Response) {
            const result = await Storage.get(response.data.s3File, {
                contentType: "text/plain",
                download: true
            });
            const responseStr = await result.Body.text();
            response.data = JSON.parse(responseStr);
        }

        return response.data;
    } catch (error) {
        if (processError) {
            const code = error && error.response && error.response.status;

            if (
                code === 401 ||
                ((typeof error === "string" || error instanceof String) &&
                    error.toLowerCase().includes("not authenticated"))
            ) {
                await showAlert({
                    title: "Logged out",
                    text:
                        "You were logged out for security reasons. Please login again.",
                    icon: "error",
                    buttons: true
                });

                return await UserActions.signOut();
            }

            if (code === 426) {
                const profile = error?.response?.data?.profile;
                const message = error?.response?.data?.message;
                if (profile) {
                    //if you are localhost then set your "simulatedProfile"
                    //to remember that a redirect has indeed occurred
                    //otherwise do what the server tells you to and do a switch
                    console.info(
                        `Upgrade detected moving to ${profile}`,
                        message
                    );
                    if (window.location.hostname === "localhost") {
                        setCookie(
                            "simulatedLocalRedirectProfile",
                            profile,
                            "/",
                            30
                        );
                        window.location = `/${profile}/index.html`;
                    } else {
                        window.location = `/${profile}/index.html`;
                    }

                    return;
                } else {
                    //what do you do if no profile was sent back?? logout? catastrophe?
                }
            }

            if (code === 503) {
                const subCode = error?.response?.data?.subCode;
                if (subCode === "NO_PERM") {
                    throw {
                        title: "Temporary Outage",
                        errorMessage:
                            "We are currently performing some maintenance on our servers. Please try again in a few moments"
                    };
                }
            }
            //what to do in read only mode

            const data =
                (error && error.response && error.response.data) || error;
            const text =
                (data && data.errorMessage) ||
                (data && data.toString()) ||
                "Something went wrong";
            const title = "Something went wrong";

            throw {
                title,
                text,
                ...data
            };
        }

        throw error;
    }
}

function Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12:
            case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(
                    ((c & 0x0f) << 12) |
                        ((char2 & 0x3f) << 6) |
                        ((char3 & 0x3f) << 0)
                );
                break;
        }
    }

    return out;
}
