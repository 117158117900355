import React, { Component, Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Compact from "./layouts/layout-compact/layout-compact.component";
import { withUrlSync } from "./redux/ReduxUrlSync";
import LayoutLoader from "./layouts/components/layout-loader/layout-loader.component";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ErrorView from "../src/components/ErrorView";
import pkgConfig from "../package.json";
import { getUser } from "utils/auth";
import history from "utils/history";
import config from "cfg/config";
import Overlay from "components/Overlay";

import { Auth } from "aws-amplify";
import SSPAnalyzer from "./containers/ssp/SSPAnalyzer";

let ErrorBoundary = undefined;

if (config.bugsnag) {
    const bsnagParams = {
        apiKey: config.bugsnag.apiKey,
        appVersion: pkgConfig.version,
        plugins: [new BugsnagPluginReact()],
        maxBreadcrumbs: 5,
        onError: function(event) {
            console.error(event);

            if (/ChunkLoadError/.test(event.errors[0].errorClass)) {
                event.ignore = true;
                return false;
            }

            const authError = () => {
                // User should be signed in for auth error to happen
                if (
                    localStorage.getItem("amplify-authenticator-authState") !=
                    "signedIn"
                )
                    return;

                const handleError = (err) => {
                    let lastAuthUser;
                    let idToken;
                    for (let key in localStorage) {
                        if (key.endsWith("LastAuthUser")) {
                            lastAuthUser = localStorage.getItem(key);
                        }

                        if (key.endsWith("idToken")) {
                            idToken = localStorage.getItem(key);
                        }
                    }

                    console.log(
                        `user data is broken. ${err}. AWS Cognito attrs: ` +
                            JSON.stringify({
                                lastAuthUser,
                                idToken
                            })
                    );

                    event.ignore = true;
                    localStorage.clear();
                    Auth.signOut({ global: true });
                };

                const checkUserData = () => {
                    let foundUserData = false;

                    // Ensure that user is signed in
                    for (let key in localStorage) {
                        if (key.endsWith("userData")) {
                            foundUserData = true;
                            let userData = localStorage.getItem(key);

                            if (!userData) {
                                handleError("string is empty!!");
                                return;
                            }

                            try {
                                userData = JSON.parse(userData);

                                if (!Object.keys(userData).length) {
                                    handleError("JSON object is empty!!");
                                    return;
                                }
                            } catch (e) {
                                handleError("not well formed JSON!!");
                                return;
                            }
                        }
                    }

                    if (!foundUserData) {
                        handleError("localStorageKey not found!!");
                        return;
                    }
                };

                checkUserData();
            };

            authError();

            if (event.ignore) {
                return false;
            }

            let user;
            try {
                user = getUser();
            } catch (e) {
                // ignore error here.
            }

            if (!user) {
                console.log("No authenticated user found.");
                return;
            }

            event.setUser(
                user.username,
                user.attributes && user.attributes.email,
                user.attributes &&
                    user.attributes.given_name +
                        " " +
                        user.attributes.family_name
            );
        }
    };

    Bugsnag.start(bsnagParams);

    ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
}

const asyncComponent = (importStatement) => {
    const Component = withUrlSync(React.lazy(importStatement));
    Component.preload = importStatement;
    return Component;
};

const AsyncNotFound = asyncComponent(() =>
    import("./containers/not-found/not-found.component")
);

// NEW ROUTES
const AsyncProfile = asyncComponent(() => import("./containers/pages/Profile"));
const AsyncBrowseSalesOrders = asyncComponent(() =>
    import("./containers/sales/search/SalesOrderSearch")
);
const AsyncBrowseExpenses = asyncComponent(() =>
    import("./containers/expenses/BrowseExpenses")
);
const AsyncSalesOrder = asyncComponent(() =>
    import("./containers/salesorder/SalesOrder")
);
const AsyncVersionedSalesOrder = asyncComponent(() =>
    import("./containers/salesorder/VersionedSalesOrder")
);
const AsyncMstrReport = asyncComponent(
    () => import("./containers/reports/MstrReport"),
    { container: { display: "flex" } }
);
const AsyncMstrNativeReportParent = asyncComponent(
    () => import("./containers/reports/MstrNativeReportParent"),
    { container: { display: "flex" } }
);
const AsyncMstrNativeReport = asyncComponent(
    () => import("./containers/reports/MstrNativeReport"),
    { container: { display: "flex" } }
);
const AsyncMstrLogin = asyncComponent(
    () => import("./containers/reports/MstrLogin"),
    { container: { display: "flex" } }
);
const AsyncRevenueArrangement = asyncComponent(() =>
    import("./containers/revenue/RevenueArrangement")
);
const AsyncCustomerContracts = asyncComponent(() =>
    import("./containers/customer/CustomerView.js")
);
const AsyncProductView = asyncComponent(() =>
    import("./containers/product/ProductView.js")
);
const AsyncCustomerSearchView = asyncComponent(() =>
    import("./containers/customer/customer/CustomerSearch.js")
);
const AsyncProductsView = asyncComponent(() =>
    import("./containers/product/ProductSearchView.js")
);
const AsyncForexExchangeView = asyncComponent(() =>
    import("./containers/forexexchange/ForexExchange.js")
);
const AsyncAuditTrailView = asyncComponent(() =>
    import("./containers/audit/AuditTrail.js")
);
const AsyncSecurityEventsView = asyncComponent(() =>
    import("./containers/audit/SecurityEvents.js")
);

const AsyncProcessesView = asyncComponent(() =>
    import("./containers/admin/Processes.js")
);

const AsyncJobErrorsSearch = asyncComponent(() =>
    import("./containers/errors/JobErrorsSearch.js")
);
const AsyncJobErrorsDetail = asyncComponent(() =>
    import("./containers/errors/JobErrorsDetail.js")
);

const AsyncBrowseExportData = asyncComponent(() =>
    import("./containers/export/ExportDataParentView.js")
);

// Sync pages.
const AsyncSyncSearch = asyncComponent(() =>
    import("./containers/sync/sync-search/SyncSearch")
);
const AsyncSyncViewParent = asyncComponent(() =>
    import("./containers/sync/sync-view/SyncViewParent")
);

//Landing
const AsyncDashBoard = asyncComponent(() =>
    import("./containers/dashboards/sales/dashboard.component")
);

//EstimatedSellingPrice Routes
const AsyncEstimatedSellingPrice = asyncComponent(() =>
    import("./containers/ssp/SSPSearchParent")
);

const AsyncRestoreSellingPrice = asyncComponent(() =>
    import("./containers/ssp/restore/SSPRestoreParent")
);

const AsyncClientTables = asyncComponent(() =>
    import("./containers/client-tables/ClientTableSearchParent")
);
const AsyncSSPAnalyzer = asyncComponent(() =>
    import("./containers/ssp/SSPAnalyzer")
);

// Others
const AsyncJournalAccountsPage = asyncComponent(() =>
    import("./containers/settings/views/JournalAccountsParentView")
);
const AsyncManualAdjustmentPage = asyncComponent(() =>
    import("./containers/adjustments/AdjustmentsSearch")
);

const AsyncProductsPage = asyncComponent(() =>
    import("./containers/product/ProductSearchView")
);

const AsyncOrganization = asyncComponent(() =>
    import("./containers/organization/OrgSettings")
);
const AsyncRootAdmin = asyncComponent(() =>
    import("./containers/admin/RootAdmin")
);
const AsyncAdminAction = asyncComponent(() =>
    import("./containers/admin/AdminActions")
);

const AsyncControlErrors = asyncComponent(() =>
    import("./containers/control-errors/ControlErrors")
);

const AsyncRevenueStoryReportsPage = asyncComponent(() =>
    import("./containers/reports/RevenueStoryReport.js")
);

/** Accounting Central */
const AsyncACRootAdmin = asyncComponent(() =>
    import("./containers/admin/RootAdmin")
);
const AsyncACAdminAction = asyncComponent(() =>
    import("./containers/admin/AdminActions")
);
const AsyncACProcessesView = asyncComponent(() =>
    import("./containers/admin/Processes.js")
);
const AsyncACSyncSearch = asyncComponent(() =>
    import("./containers/sync/sync-search/SyncSearch")
);
const AsyncACSyncViewParent = asyncComponent(() =>
    import("./containers/sync/sync-view/SyncViewParent")
);
const AsyncACDashboardPage = asyncComponent(() =>
    import("./containers/ac/HomePage")
);
const AsyncACAccountReceivablePage = asyncComponent(() =>
    import("./containers/ac/AccountsReceivablePage")
);
const AsyncACCashPage = asyncComponent(() =>
    import("./containers/ac/CashPage")
);
const AsyncACRevenuePage = asyncComponent(() =>
    import("./containers/ac/RevenuePage")
);
const AsyncACExpensePage = asyncComponent(() =>
    import("./containers/ac/ExpensePage")
);
const AsyncACTaxPayablePage = asyncComponent(() =>
    import("./containers/ac/TaxPayablePage")
);
const AsyncACBadDebtPage = asyncComponent(() =>
    import("./containers/ac/BadDebtPage")
);
const AsyncACCustomersPage = asyncComponent(() =>
    import("./containers/ac/CustomersPage")
);
const AsyncACCustomerDetailsPage = asyncComponent(() =>
    import("./containers/ac/CustomerDetailsPage")
);
const AsyncACTrialBalancePage = asyncComponent(() =>
    import("./containers/ac/TrialBalancePage")
);
const AsyncACJournalEntriesPage = asyncComponent(() =>
    import("./containers/ac/JournalEntriesPage")
);
const AsyncACJournalPostingPage = asyncComponent(() =>
    import("./containers/ac/JournalPostingPage")
);
const AsyncACPeriodClosePage = asyncComponent(() =>
    import("./containers/ac/PeriodClosePage")
);
const AsyncACAccountingSettingsPage = asyncComponent(() =>
    import("./containers/ac/AccountingSettingsPage")
);
const AsyncACAccountsPage = asyncComponent(() =>
    import("./containers/ac/AccountsPage")
);
const AsyncACJournalAccountMappingsPage = asyncComponent(() =>
    import("./containers/ac/JournalAccountMappingsPage")
);
const AsyncACTeamMembersPage = asyncComponent(() =>
    import("./containers/ac/TeamMembersPage")
);
const AsyncACSingleReportPage = asyncComponent(() =>
    import("./containers/ac/ReportPage")
);
const AsyncACTransactionPeriodReconPage = asyncComponent(() =>
    import("./containers/ac/TransactionPeriodReconPage")
);
const AsyncACBillingPeriodReconPage = asyncComponent(() =>
    import("./containers/ac/BillingPeriodReconPage")
);
const AsyncACJobErrorsPage = asyncComponent(() =>
    import("./containers/ac/JobErrorsPage")
);

const AsyncACAccountSummaryReport = asyncComponent(() =>
    import("./containers/ac/AccountSummaryReportPage")
);

setTimeout(() => {
    AsyncProfile.preload();
    AsyncBrowseSalesOrders.preload();
    AsyncSalesOrder.preload();
    AsyncMstrReport.preload();
    AsyncMstrNativeReportParent.preload();
    AsyncMstrNativeReport.preload();
    AsyncRevenueArrangement.preload();
    AsyncCustomerContracts.preload();
    AsyncProductView.preload();
    AsyncCustomerSearchView.preload();
    AsyncSyncSearch.preload();
    AsyncProductsView.preload();
    AsyncForexExchangeView.preload();
    AsyncAuditTrailView.preload();
    AsyncSecurityEventsView.preload();

    AsyncJobErrorsSearch.preload();
    AsyncBrowseExportData.preload();

    AsyncSyncViewParent.preload();

    AsyncDashBoard.preload();

    AsyncEstimatedSellingPrice.preload();
    AsyncRestoreSellingPrice.preload();
    AsyncClientTables.preload();
    AsyncSSPAnalyzer.preload();

    AsyncSyncViewParent.preload();
    AsyncProductsPage.preload();

    AsyncOrganization.preload();

    AsyncControlErrors.preload();

    AsyncRevenueStoryReportsPage.preload();
}, 1000);

const AppRoute = ({
    analyticName,
    component: Component,
    licenseLevel,
    layout: Layout,
    ...rest
}) => {
    if (!history.historyInstance) {
        const h = useHistory();
        history.historyInstance = h;
    }

    function addAnalyticName(props) {
        props.analyticName = analyticName;
        return props;
    }

    const component = (props) => (
        <Overlay licenseLevel={licenseLevel}>
            <Component {...addAnalyticName(props)} />
        </Overlay>
    );

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    {ErrorBoundary && (
                        <ErrorBoundary FallbackComponent={ErrorView}>
                            {component(props)}
                        </ErrorBoundary>
                    )}
                    {ErrorBoundary || component(props)}
                </Layout>
            )}
        />
    );
};

const CompactLayout = (props) => <Compact>{props.children}</Compact>;

export default ({
    childProps,
    currentOrganization = {},
    rrEnabled = true,
    disableActionsForQuickView
}) => {
    const activeLayout = CompactLayout;

    const { disableReporting = false } = currentOrganization;

    return (
        <Suspense fallback={<LayoutLoader />}>
            <Switch>
                <AppRoute
                    path="/ac/:orgId"
                    licenseLevel={1}
                    exact
                    component={AsyncACDashboardPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/accountsReceivable"
                    licenseLevel={1}
                    exact
                    component={AsyncACAccountReceivablePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/cash"
                    licenseLevel={1}
                    exact
                    component={AsyncACCashPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/revenue"
                    licenseLevel={1}
                    exact
                    component={AsyncACRevenuePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/expense"
                    licenseLevel={1}
                    exact
                    component={AsyncACExpensePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/tax-payable"
                    licenseLevel={1}
                    exact
                    component={AsyncACTaxPayablePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/debt"
                    licenseLevel={1}
                    exact
                    component={AsyncACBadDebtPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/customers"
                    licenseLevel={1}
                    exact
                    component={AsyncACCustomersPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/customers/:customerId"
                    licenseLevel={1}
                    exact
                    component={AsyncACCustomerDetailsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/trialbalance"
                    licenseLevel={1}
                    exact
                    component={AsyncACTrialBalancePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/journal-entries"
                    licenseLevel={1}
                    exact
                    component={AsyncACJournalEntriesPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/journal-posting"
                    licenseLevel={1}
                    exact
                    component={AsyncACJournalPostingPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/period-close"
                    licenseLevel={1}
                    exact
                    component={AsyncACPeriodClosePage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/accounting-settings"
                    licenseLevel={1}
                    exact
                    component={AsyncACAccountingSettingsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/accounts"
                    licenseLevel={1}
                    exact
                    component={AsyncACAccountsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/jams"
                    licenseLevel={1}
                    exact
                    component={AsyncACJournalAccountMappingsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/team"
                    licenseLevel={1}
                    exact
                    component={AsyncACTeamMembersPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/report"
                    licenseLevel={1}
                    exact
                    component={AsyncACSingleReportPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/transaction-period-recon"
                    licenseLevel={1}
                    exact
                    component={AsyncACTransactionPeriodReconPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/billing-period-recon"
                    licenseLevel={1}
                    exact
                    component={AsyncACBillingPeriodReconPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/errors"
                    licenseLevel={1}
                    exact
                    component={AsyncACJobErrorsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/transactions-to-journal-recon"
                    licenseLevel={1}
                    exact
                    component={AsyncACAccountSummaryReport}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/admin/merchants"
                    exact
                    component={AsyncACRootAdmin}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/admin/action"
                    exact
                    component={AsyncACAdminAction}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/admin/processes"
                    exact={true}
                    component={AsyncACProcessesView}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/pages/sync"
                    licenseLevel={1}
                    exact
                    component={AsyncACSyncSearch}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/ac/:orgId/pages/sync/:syncId"
                    licenseLevel={1}
                    exact
                    component={AsyncACSyncViewParent}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/"
                    exact
                    component={
                        rrEnabled === true
                            ? disableActionsForQuickView
                                ? AsyncMstrNativeReportParent
                                : AsyncDashBoard
                            : AsyncACDashboardPage
                    }
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/admin"
                    exact
                    component={AsyncRootAdmin}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/admin/action"
                    exact
                    component={AsyncAdminAction}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId"
                    exact
                    component={AsyncDashBoard}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/dashboards"
                    exact
                    component={
                        rrEnabled === true
                            ? disableActionsForQuickView
                                ? AsyncMstrNativeReportParent
                                : AsyncDashBoard
                            : AsyncACDashboardPage
                    }
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/profile"
                    exact
                    component={AsyncProfile}
                    props={childProps}
                    layout={activeLayout}
                />
                {disableReporting || (
                    <AppRoute
                        path="/:orgId/mstr-report"
                        exact
                        component={AsyncMstrReport}
                        props={childProps}
                        layout={activeLayout}
                    />
                )}
                {disableReporting || (
                    <AppRoute
                        path="/:orgId/mstr-native-report-parent"
                        exact
                        component={AsyncMstrNativeReportParent}
                        props={childProps}
                        layout={activeLayout}
                    />
                )}
                {disableReporting || (
                    <AppRoute
                        path="/:orgId/mstr-native-report/:reportType/:reportId"
                        exact
                        component={AsyncMstrNativeReportParent}
                        props={childProps}
                        licenseLevel={1}
                        layout={activeLayout}
                    />
                )}
                {disableReporting || (
                    <AppRoute
                        path="/:orgId/reports/:reportType/:reportId"
                        exact
                        component={AsyncMstrNativeReportParent}
                        props={childProps}
                        layout={activeLayout}
                    />
                )}
                {disableReporting || (
                    <AppRoute
                        path="/:orgId/mstr-login"
                        exact
                        component={AsyncMstrLogin}
                        props={childProps}
                        layout={activeLayout}
                    />
                )}

                <AppRoute
                    path="/:orgId/pages/sales"
                    exact
                    component={AsyncBrowseSalesOrders}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/expenses"
                    licenseLevel={2}
                    exact
                    component={AsyncBrowseExpenses}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/salesorder/:salesOrderId"
                    exact
                    component={AsyncSalesOrder}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/pages/salesorder/v/:salesOrderId"
                    exact
                    component={AsyncVersionedSalesOrder}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/pages/salesorder/:salesOrderId/revenue"
                    exact
                    component={AsyncRevenueArrangement}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/esp"
                    licenseLevel={2}
                    exact
                    component={AsyncEstimatedSellingPrice}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/ssp/analyzer"
                    component={AsyncSSPAnalyzer}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/esp/version/:versionId"
                    exact
                    component={AsyncRestoreSellingPrice}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/client-table/:entity"
                    exact
                    component={AsyncClientTables}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/journalaccounts"
                    licenseLevel={1}
                    exact
                    component={AsyncJournalAccountsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/adjustments"
                    licenseLevel={1}
                    exact
                    component={AsyncManualAdjustmentPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/products"
                    exact
                    component={AsyncProductsPage}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/organization"
                    exact
                    component={AsyncOrganization}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/organization/settings"
                    exact
                    component={AsyncOrganization}
                    props={childProps}
                    layout={activeLayout}
                />

                {/* Sync end points. (start) */}
                <AppRoute
                    path="/:orgId/pages/sync"
                    licenseLevel={1}
                    exact
                    component={AsyncSyncSearch}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/pages/sync/:syncId"
                    licenseLevel={1}
                    exact
                    component={AsyncSyncViewParent}
                    props={childProps}
                    layout={activeLayout}
                />
                {/* Sync end points. (end) */}

                <AppRoute
                    path="/:orgId/pages/:customerId/customer"
                    exact
                    component={AsyncCustomerContracts}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/customers"
                    analyticName="customers"
                    exact
                    component={AsyncCustomerSearchView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/products"
                    exact
                    component={AsyncProductsView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/forex"
                    licenseLevel={1}
                    exact
                    component={AsyncForexExchangeView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/audit"
                    exact={true}
                    component={AsyncAuditTrailView}
                    props={childProps}
                    layout={activeLayout}
                />
                <AppRoute
                    path="/:orgId/pages/security"
                    exact={true}
                    component={AsyncSecurityEventsView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/admin/processes"
                    exact={true}
                    component={AsyncProcessesView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/:productId/product"
                    exact
                    component={AsyncProductView}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/revlock-errors"
                    exact
                    component={AsyncJobErrorsSearch}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/revlock-errors-detail"
                    exact
                    component={AsyncJobErrorsDetail}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/pages/export"
                    exact
                    component={AsyncBrowseExportData}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/control-errors"
                    exact
                    component={AsyncControlErrors}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute
                    path="/:orgId/reports/revenue-story"
                    exact={true}
                    component={AsyncRevenueStoryReportsPage}
                    props={childProps}
                    layout={activeLayout}
                />

                <AppRoute component={AsyncNotFound} layout={activeLayout} />
            </Switch>
        </Suspense>
    );
};
