import shortid from "shortid";
import ModificationType from "./reference/ModificationType";
import RecognitionRule from "./reference/RecognitionRule";
import * as AccountingUtils from "./AccountingUtils";
import { Utils, Comparator, moment, Period } from "revlock-webutils";
import {
    getStandAlonePrice,
    getCreditNoteLineItems,
    getNonCreditNoteLineItems
} from "./StandalonePriceHelper";
import * as PSHelper from "./ProfessionalServiceHelper";
import { Codes } from "../../build/core/ErrorCode";
import { selectSSP } from "./SSPSelectorHelper";

import Big from "big.js";
import PointInTimePlan from "./plan/PointInTimePlan";
import _ from "lodash";

import {
    getAutoCreateSSP,
    prefillZeroAtStart,
    buildPlan,
    calculatePlanAmount
} from "./ArrangementUtils";
import * as ExpenseArrangementHelper from "./ExpenseArrangementHelper";
import ItemType from "./reference/ItemType";
import { SalesOrderPreprocessor } from "../Accounting";

const { formatDate } = Utils;

const defaultSSPForProductCode = (id, attributes) => ({
    attributes: attributes || {},
    commissionRecognitionRuleId: 2,
    deliveryStartDate: "",
    recognitionRuleId: RecognitionRule.POINT_IN_TIME,
    id,
    standalonePrice: 0,
    recognizedOn: "startDate"
});

export function computeTermListPrice(product, startDate, endDate) {
    const listPriceAmount = product.listPriceAmount || product.listPrice;
    return computeTermAmount(product, startDate, endDate, listPriceAmount);
}

export function computeTermAmount(product, startDate, endDate, amount) {
    if (!product) return null;

    const { recurring } = product;

    if (!recurring || !product.listPricePeriodUnit) return amount;

    const { listPricePeriodUnit, listPricePeriod } = product;

    if (!amount) return null;

    const daysPerYear = 365;
    const daysPerMonth = new Big(365).div(12);
    const daysPerQuarter = new Big(365).div(4);

    let dayPrice;

    switch (listPricePeriodUnit) {
        case "years":
            dayPrice = new Big(amount).div(listPricePeriod).div(daysPerYear);
            break;
        case "months":
            dayPrice = new Big(amount).div(listPricePeriod).div(daysPerMonth);
            break;
        case "quarters":
            dayPrice = new Big(amount).div(listPricePeriod).div(daysPerQuarter);
            break;
    }

    function termInDays() {
        if (startDate && endDate) {
            const start = moment(startDate, "YYYY-MM-DD");
            const end = moment(endDate, "YYYY-MM-DD");

            if (start.date() == end.date() && start.month() == end.month()) {
                return end.diff(start, "years") * 365;
            }
            //if you are on the same day/month combo then go ahead and just compute flatly against year

            return end.diff(start, "days");
            //otherwise compute exact days
        }

        if (product.term) {
            return new Big(product.term).times(daysPerMonth);
        }
        //convert term in months as specified into days

        return daysPerMonth;
        //assume that the term is 1 month if not specified for a recurring product
    }

    const term = termInDays();

    const listPrice = dayPrice.times(term).toFixed(4);
    return Number(listPrice);
}

export function populateClientAttributes(clientAttributes, entities, params) {
    clientAttributes &&
        entities &&
        entities.forEach((entity) => {
            clientAttributes = clientAttributes.filter(
                (attribute) => attribute.entity == entity
            );

            let attributes = {};

            clientAttributes.forEach((attribute) => {
                attributes[attribute.name] = AccountingUtils.evaluateExpression(
                    attribute.expression,
                    params
                );
            });

            params[entity].attributes = Object.assign(
                {},
                params[entity].attributes,
                attributes
            );
        });
}

const populateAnnualExtendedSalePrice = (salesOrderItem) => {
    const { startDate, endDate } = salesOrderItem;

    let duration = 12;

    if (startDate && endDate) {
        duration = Utils.getMonthDiff(startDate, endDate, true);
    }

    if (duration == 0) {
        duration = 12;
    }

    salesOrderItem.annualExtendedSalePrice =
        salesOrderItem.extendedSalePrice * (12 / duration);
    salesOrderItem.duration = duration;
};

const getSOI = (salesOrder, itemId) => {
    const soi = salesOrder.salesOrderItem.find((item) => {
        const _itemId = item.rootId || item.id;
        if (_itemId === itemId) {
            return item;
        }
    });

    return soi;
};

export const getRevenueArrangementItemBySalesOrderItem = (
    revenueArrangement,
    salesOrderItem
) => {
    return (
        revenueArrangement &&
        revenueArrangement.revenueArrangementItem.find((rai) => {
            let itemId = rai.salesOrderItem.rootId || rai.salesOrderItem.id;
            const _itemId = salesOrderItem.rootId || salesOrderItem.id;
            if (itemId === _itemId && rai.itemType != ItemType.SPLIT_FROZEN) {
                return rai;
            }
        })
    );
};

export function populateDeliveryDates(
    salesOrder,
    revenueArrangement,
    soi,
    ssp,
    rai,
    errors,
    customFields,
    endDateInclusive,
    dereferenceRuleHasPriority = false,
    isApiCall = false,
    disableProduct = false
) {
    soi.isActive = false;
    let baseSalesOrderItems;

    const isCreditNote = () => {
        soi.invoiceNumber &&
            soi.referenceInvoiceNumber &&
            soi.invoiceNumber !== soi.referenceInvoiceNumber;
    };

    const getMinDate = (key, productId) => {
        let date;
        baseSalesOrderItems = salesOrder.salesOrderItem;
        const isCreditNoteItem = isCreditNote();
        for (let item of baseSalesOrderItems) {
            const serviceDate = item[key];
            if (
                !item.product ||
                item.product.id != productId ||
                item.product.entityType !== "BASE"
            )
                continue;
            if (!date || Period.isAfter(date, serviceDate)) {
                date = serviceDate;
            }
        }

        return date;
    };

    const getMaxDate = (key, productId) => {
        let date;
        baseSalesOrderItems = salesOrder.salesOrderItem;
        for (let item of baseSalesOrderItems) {
            const serviceDate = item[key];
            if (
                !item.product ||
                item.product.id != productId ||
                item.product.entityType !== "BASE"
            )
                continue;
            if (!date || Period.isAfter(serviceDate, date)) {
                date = serviceDate;
            }
        }

        return date;
    };

    const lookup = (dateField, lookupSalesOrderItem) => {
        if (
            dateField.match(/Order Start Date/gi) ||
            dateField.match(/Order Date/gi)
        ) {
            return salesOrder.orderDate;
        }
        //special case for order start date

        // special case po items
        if (dateField == "Min Start Date") {
            return getMinDate("startDate", soi.product.parent);
        } else if (dateField == "Max Start Date") {
            return getMaxDate("startDate", soi.product.parent);
        } else if (dateField == "Min End Date") {
            return getMinDate("endDate", soi.product.parent);
        } else if (dateField == "Max End Date") {
            return getMaxDate("endDate", soi.product.parent);
        }

        let lookupField = dateField;

        const cf =
            customFields &&
            customFields.find((c) => c.displayName === dateField);
        if (cf) {
            lookupField = cf.name;
        }

        if (salesOrder[lookupField]) {
            return salesOrder[lookupField];
        }

        if (
            salesOrder.orderAttributes &&
            salesOrder.orderAttributes[lookupField]
        ) {
            return salesOrder.orderAttributes[lookupField];
        }

        if (
            lookupSalesOrderItem &&
            soi.attributes &&
            soi.attributes[lookupField]
        ) {
            return soi.attributes[lookupField];
        }

        return null;
    };

    const productStartDate = () => {
        if (
            !soi.product ||
            !soi.product.serviceStartDate ||
            !soi.product.serviceStartDate.trim()
        )
            return null;

        const {
            serviceStartDate,
            serviceStartOffset,
            serviceStartOffsetPeriodUnit
        } = soi.product;

        // salesOrderItem is po item of credit note then use parent credit note's dates directly.
        if (soi.product.entityType === "PO" && soi.isCreditNote) {
            const parentSalesOrderItem = salesOrder.salesOrderItem.find(
                (item) => item.id === soi.parentSalesOrderItemId
            );
            return parentSalesOrderItem && parentSalesOrderItem.startDate;
        }

        let startDate = Utils.isDate(serviceStartDate)
            ? serviceStartDate
            : lookup(serviceStartDate);

        if (startDate) {
            if (serviceStartOffset) {
                startDate = moment(startDate, "YYYY-MM-DD").add(
                    serviceStartOffset,
                    serviceStartOffsetPeriodUnit
                );
            }
            return Utils.formatDate(startDate);
        }

        return null;
    };

    const searchForDateField = (searchField, lookupSalesOrderItem) => {
        if (!searchField || !searchField.trim() || searchField === "manualDate")
            return null;

        return moment.isDate(searchField)
            ? searchField
            : lookup(searchField, lookupSalesOrderItem);
    };

    const salesOrderDereferenceStartDate = () =>
        searchForDateField(soi.dereferencedStartDate);
    const sspStartDate = (lookupSalesOrderItem) =>
        searchForDateField(ssp && ssp.deliveryStartDate, lookupSalesOrderItem);

    // In case dereference rules needs to take priority over user selected date,
    // We delete the date and attemp to apply dereference rules.
    let manualStartDate = undefined;
    if (
        dereferenceRuleHasPriority &&
        soi.dereferencedStartDate === "manualDate"
    ) {
        manualStartDate = soi.startDate;
        delete soi.startDate;
        delete soi.dereferencedStartDate;
    }

    // We allow overriding the start date on the UI by specifying a de-referencing field.
    // You can also override by providing it in the SSP or the product
    if (soi.dereferencedStartDate !== "manualDate") {
        // If dereference source for start date is not salesOrder clear it.
        if (
            soi.dereferencedStartDateSource &&
            soi.dereferencedStartDateSource !== "salesOrder"
        ) {
            soi.dereferencedStartDate = undefined;
        }

        // Always reset dereference start date value as we will recalcualte it.
        delete soi.dereferencedStartDateValue;
        delete soi.startDate;

        //0: override at the sales order item level if the user has specified a value
        //to use for a de-reference lookup
        if (
            soi.dereferencedStartDateSource == "salesOrder" &&
            soi.dereferencedStartDate
        ) {
            soi.startDate = salesOrderDereferenceStartDate();
        }
        //1. override at the SSP or product level if the user
        //has not specified a date
        else if (ssp && ssp.deliveryStartDate) {
            soi.startDate = sspStartDate(true);
            soi.dereferencedStartDate = ssp.deliveryStartDate;
            soi.dereferencedStartDateSource = "ssp";
        } else if (soi.product && soi.product.serviceStartDate) {
            soi.startDate = productStartDate();
            soi.dereferencedStartDate = soi.product.serviceStartDate;
            soi.dereferencedStartDateSource = "product";
            if (
                !soi.isCreditNote &&
                Utils.isDate(soi.product.serviceStartDate)
            ) {
                soi.dereferencedStartDate = "Absolute Date";
            }
        } else {
            soi.dereferencedStartDateValue = undefined;
            soi.dereferencedStartDateSource = undefined;
        }
    }

    // Unable to apply dereference rules, let's default to the manual date that was set as part of upload
    // or from UI
    if (dereferenceRuleHasPriority && !soi.startDate && manualStartDate) {
        soi.startDate = manualStartDate;
        soi.dereferencedStartDate = "manualDate";
    }

    if (soi.startDate) {
        // We were able to get a good start date
        soi.isActive = true;
        soi.dereferencedStartDateValue = soi.startDate;
    }

    if (!soi.startDate) {
        //we set a default date here as no date here causes a publishing issuer or something
        //note that the order itself is inactive.
        soi.startDate = salesOrder.orderDate;
    }

    const productEndDate = () => {
        if (
            !soi.product ||
            !soi.product.serviceEndDate ||
            !soi.product.serviceEndDate.trim()
        )
            return null;

        const {
            serviceEndDate,
            serviceEndOffset,
            serviceEndOffsetPeriodUnit
        } = soi.product;

        // salesOrderItem is po item of credit note then use parent credit note's dates directly.
        if (soi.product.entityType === "PO" && soi.isCreditNote) {
            const parentSalesOrderItem = salesOrder.salesOrderItem.find(
                (item) => item.id === soi.parentSalesOrderItemId
            );
            return parentSalesOrderItem && parentSalesOrderItem.endDate;
        }

        let endDate = Utils.isDate(serviceEndDate)
            ? serviceEndDate
            : lookup(serviceEndDate);

        if (endDate) {
            if (serviceEndOffset) {
                endDate = moment(endDate, "YYYY-MM-DD").add(
                    serviceEndOffset,
                    serviceEndOffsetPeriodUnit
                );
            }

            return Utils.formatDate(endDate);
        }

        return null;
    };
    const salesOrderDereferenceEndDate = () =>
        searchForDateField(soi.dereferencedEndDate);
    const sspEndDate = (lookupSalesOrderItem) =>
        searchForDateField(ssp && ssp.deliveryEndDate, lookupSalesOrderItem);

    // In case dereference rules needs to take priority over user selected date,
    // We delete the date and attempt to apply dereference rules.
    // If the sales order is terminated, do not apply dereference rules
    let manualEndDate = undefined;
    let userSelectedEndDate = undefined;
    if (
        dereferenceRuleHasPriority &&
        soi.dereferencedEndDate === "manualDate" &&
        !salesOrder.isTerminated
    ) {
        manualEndDate = soi.endDate;
        userSelectedEndDate = soi.userSelectedEndDate;
        delete soi.endDate;
        delete soi.dereferencedEndDate;
        delete soi.userSelectedEndDate;
    }

    if (soi.dereferencedEndDate !== "manualDate") {
        // If dereference source for end date is salesOrder that can't clear it.
        // dont clear for other source, as we will recalculate it.
        if (
            soi.dereferencedEndDateSource &&
            soi.dereferencedEndDateSource !== "salesOrder"
        )
            soi.dereferencedEndDate = undefined;

        // Always reset dereference end date value as we will recalcualte it.
        delete soi.dereferencedEndDateValue;
        delete soi.endDate;

        //0: override at the sales order item level if the user has specified a value
        //to use for a de-reference lookup
        if (soi.dereferencedEndDateSource == "salesOrder") {
            soi.endDate = salesOrderDereferenceEndDate();
        } else if (ssp && ssp.deliveryEndDate) {
            //1. override at the SSP or product level if the user
            //has not specified a date
            soi.endDate = sspEndDate(true);
            soi.dereferencedEndDate = ssp.deliveryEndDate;
            soi.dereferencedEndDateSource = "ssp";
        } else if (soi.product && soi.product.serviceEndDate) {
            soi.endDate = productEndDate();
            soi.dereferencedEndDate = soi.product.serviceEndDate;
            soi.dereferencedEndDateSource = "product";
            if (!soi.isCreditNote && Utils.isDate(soi.product.serviceEndDate)) {
                soi.dereferencedEndDate = "Absolute Date";
            }
        } else {
            soi.dereferencedEndDateValue = undefined;
            soi.dereferencedEndDateSource = undefined;
        }

        if (soi.endDate) {
            soi.dereferencedEndDateValue = soi.endDate;
        }
    }

    // derefence end date is manual
    if (soi.startDate && !soi.userSelectedEndDate) {
        if (soi.termSource != "salesOrder") delete soi.term;

        let term;

        if (soi.term != undefined && soi.termSource == "salesOrder") {
            // Term in sales order takes priority over dereferenced end date in product and ssp.
            if (
                !soi.dereferencedEndDateSource ||
                ["product", "ssp"].includes(soi.dereferencedEndDateSource)
            ) {
                term = soi.term;
                soi.termSource = "salesOrder";
            }
        } else if (ssp.term != undefined && ssp.term) {
            // Term in SSP takes priority over dereferenced end date in product.
            if (
                !soi.dereferencedEndDateSource ||
                soi.dereferencedEndDateSource == "product"
            ) {
                term = ssp.term;
                soi.termSource = "ssp";
            }
        } else if (
            !disableProduct &&
            soi.product.term != undefined &&
            soi.product.term
        ) {
            if (!soi.dereferencedEndDateSource) {
                term = soi.product.term;
                soi.termSource = "product";
            }
        }

        if (term && soi.isActive) {
            soi.term = term;

            let endDate = Utils.addMonthsAsDuration(soi.startDate, soi.term);

            if (endDateInclusive) endDate = Utils.addDays(endDate, -1);

            soi.endDate = Utils.formatDate(endDate);

            delete soi.dereferencedEndDateValue;
            delete soi.dereferencedEndDateSource;
            delete soi.dereferencedEndDate;
        }
    }

    // Unable to apply dereference rules, let's default to the manual date that was set as part of upload
    // or from UI
    if (dereferenceRuleHasPriority && !soi.endDate && manualEndDate) {
        soi.endDate = manualEndDate;
        soi.dereferencedEndDate = "manualDate";
        soi.userSelectedEndDate = userSelectedEndDate;
    }

    if (!soi.endDate) {
        // If recognition rule is POINT_IN_TIME than enddate is not required.
        // Otherwise mark the item inactive.
        if (ssp.recognitionRuleId !== RecognitionRule.POINT_IN_TIME) {
            soi.isActive = false;
        }

        // if null endDate just delete it.
        delete soi.endDate;
    }

    // If end date was explicitly selected by user and start date is after end date make end date equal start date.
    // This can happen when user has dereferenced end date for which the value is greater than user selected end date.
    if (
        soi.userSelectedEndDate &&
        soi.endDate &&
        soi.startDate &&
        Period.isBefore(soi.endDate, soi.startDate)
    ) {
        soi.endDate = soi.startDate;
    }

    // if recognition rule is not point in time and end date is missing, throw an exception
    if (
        isApiCall &&
        !soi.endDate &&
        ssp.recognitionRuleId !== RecognitionRule.POINT_IN_TIME
    ) {
        errors.add(Codes.TRAN_04, {
            salesOrder,
            soi,
            salesOrderId: salesOrder.id,
            orderDate: salesOrder.orderDate
        });
    }

    if (rai) {
        rai.isActive = soi.isActive;
        rai.deliveryStartDate = soi.startDate;
        // If there is a prospective modification on a salesOrder, populate the delivery dates based on the modification date
        if (rai.itemType == ItemType.SPLIT_UNRECOGNIZED) {
            rai.deliveryStartDate = revenueArrangement.modificationDate;
        }
        rai.deliveryEndDate = soi.endDate || soi.startDate;
        if (rai.itemType == ItemType.SPLIT_FROZEN) {
            rai.deliveryEndDate = revenueArrangement.modificationDate;
        }
        rai.billingDate = soi.billingDate;

        if (soi.transactionDate) {
            rai.transactionDate = soi.transactionDate;
        }
    }
}

function removeRevenueArrangementsInOpenPeriod(
    salesOrder,
    organization,
    calendarConfig
) {
    // dont worry about this if there is no close period.
    if (!organization || !salesOrder.revenueArrangement) return;

    let { isRestatementMode } = organization;

    let currentPeriod = organization.currentPeriod;

    if (isRestatementMode) {
        currentPeriod = "200001";
    }

    const revenueArrangements = [];
    const revenueArrangementIds = [];
    const deletedRevenueArrangementIds = [];
    salesOrder.revenueArrangement.forEach((revenueArrangement) => {
        const raiEffectiveActgPeriod = Period.toActgPeriod(
            revenueArrangement.effectiveDate,
            calendarConfig
        );
        const effectivePeriod = raiEffectiveActgPeriod.period;
        // Leave all except for closed period
        if (
            effectivePeriod < currentPeriod ||
            revenueArrangement.state === "New"
        ) {
            revenueArrangements.push(revenueArrangement);
            revenueArrangementIds.push(revenueArrangement.id);
        } else {
            deletedRevenueArrangementIds.push(revenueArrangement.id);
            if (revenueArrangements.length > 0) {
                let previousRevenueArrangement =
                    revenueArrangements[revenueArrangements.length - 1];

                previousRevenueArrangement.state = "Current";
                delete previousRevenueArrangement.endDate;
            }
        }
    });

    let deletedRevenueArrangement;
    const currentRaId =
        salesOrder.currentRevenueArrangement &&
        salesOrder.currentRevenueArrangement.id;

    if (deletedRevenueArrangementIds.length > 0) {
        salesOrder.deletedRevenueArrangementIds = deletedRevenueArrangementIds;
        // Assuming we will have only one revenueArrangement in the open period
        deletedRevenueArrangement = salesOrder.revenueArrangement.find(
            (ra) => ra.id == deletedRevenueArrangementIds[0]
        );
        salesOrder.revenueArrangement = revenueArrangements;
        salesOrder.revenueArrangementIds = revenueArrangementIds;
    }

    salesOrder.historicalArrangements = {};
    for (let { id, effectiveDate } of revenueArrangements) {
        salesOrder.historicalArrangements[effectiveDate] = id;
    }

    if (deletedRevenueArrangementIds.includes(currentRaId)) {
        delete salesOrder.currentRevenueArrangement;
    }
    return deletedRevenueArrangement;
}

export function populateRevenueArrangements(
    salesOrder,
    clientReferenceData,
    errors,
    isApiCall = false
) {
    let {
        options,
        journalAccounts,
        clientAttributes,
        organization,
        orgConfig,
        customCode
    } = clientReferenceData;

    // By default create the revenue plans for all sales items.
    options = Object.assign(
        {
            createRevenuePlan: true
        },
        options
    );

    const d = orgConfig.find((config) => config.id === "properties/calendar");
    const calendarConfig = d && d.value && d.value.config;

    const unearnedByConfig = orgConfig.find(
        (config) => config.id === "properties/unearned_by"
    );
    const unearnedByValue =
        (unearnedByConfig && unearnedByConfig.value) || "customer";

    const recognizeByTransactionDateConfig = orgConfig.find(
        (config) => config.id === "properties/recognize-by-transaction-date"
    );
    const recognizeByTransactionDate =
        recognizeByTransactionDateConfig &&
        recognizeByTransactionDateConfig.value;

    const psdByProductCodeConfig = orgConfig.find(
        (config) => config.id == "properties/service-delivery-by-product-code"
    );
    const psdByProductCode =
        psdByProductCodeConfig && psdByProductCodeConfig.value;

    const endDateInclusiveConfig = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive =
        endDateInclusiveConfig && endDateInclusiveConfig.value;

    const disableCnAllocationConfig = orgConfig.find(
        (config) =>
            config.id === "properties/contract-term/disable-cn-allocation"
    );
    const disableCnAllocation =
        disableCnAllocationConfig && disableCnAllocationConfig.value;

    // no sales order item yet, so bbye.
    if (!salesOrder.salesOrderItem || salesOrder.salesOrderItem.length === 0) {
        return;
    }

    const isCreditNoteItem = (soi) => {
        return (
            soi.invoiceNumber &&
            soi.referenceInvoiceNumber &&
            soi.invoiceNumber !== soi.referenceInvoiceNumber
        );
    };

    const orgConfigByKey = Utils.getOrgConfigByKey(orgConfig, false);

    if (!salesOrder.revenueArrangement) salesOrder.revenueArrangement = [];

    const revenueArrangements = salesOrder.revenueArrangement;

    salesOrder.newRevenueArrangement =
        revenueArrangements &&
        revenueArrangements.find(
            (revenueArrangement) => revenueArrangement.state === "New"
        );
    salesOrder.currentRevenueArrangement = revenueArrangements.find(
        (revenueArrangement) => revenueArrangement.state === "Current"
    );

    let removedRevenueArrangement;
    if (
        salesOrder.orderType === "Modify" ||
        salesOrder.orderType === "New" ||
        salesOrder.orderType === "Validation Error"
    ) {
        // Hold the removed revenueArrangement in the open period
        removedRevenueArrangement = removeRevenueArrangementsInOpenPeriod(
            salesOrder,
            organization,
            calendarConfig
        );
        if (
            removedRevenueArrangement &&
            salesOrder.modificationType == ModificationType.PROSPECTIVE
        ) {
            removedRevenueArrangement.revenueArrangementItem.forEach((item) => {
                if (
                    item.plan &&
                    (item.recognitionRule.id ===
                        RecognitionRule.PROPORTIONAL_PERFORMANCE ||
                        item.recognitionRule.id ===
                            RecognitionRule.PERCENT_OF_COMPLETE)
                ) {
                    buildPSPlan(
                        item,
                        salesOrder,
                        removedRevenueArrangement,
                        errors,
                        calendarConfig,
                        orgConfig,
                        orgConfigByKey
                    );
                }
            });
        }
        if (!salesOrder.newRevenueArrangement) {
            newRevenueArrangement(salesOrder);
        }
    }

    let _period = (organization && organization.currentPeriod) || "200101";

    const orgPeriod = Period.toActgPeriod(_period, calendarConfig);
    let nextArrangementEffectiveDate = orgPeriod.endDate;
    //start off assuming it is the end of the current open period

    if (salesOrder.newRevenueArrangement) {
        if (
            Period.isAfter(salesOrder.orderDate, nextArrangementEffectiveDate)
        ) {
            // order is in the future, revenue should also be in future
            nextArrangementEffectiveDate = salesOrder.orderDate;

            if (recognizeByTransactionDate) {
                let _transactionDate = getMinTransactionDate(salesOrder);

                if (
                    salesOrder.orderType !== "Modify" &&
                    _transactionDate &&
                    Period.isBefore(
                        nextArrangementEffectiveDate,
                        _transactionDate
                    )
                ) {
                    nextArrangementEffectiveDate = _transactionDate;
                } else if (salesOrder.orderType == "Modify") {
                    // recognize by transaction date is enabled, we will use first of the month to compare
                    // with the transaction date, if the max transaction date is higher than start of current system clock date
                    // then we will use start of month clock for new arrangement date.
                    const startOfCurrentMonth = Period.toActgPeriod(
                        _period,
                        calendarConfig
                    ).startDate;
                    const maxTransactionDate = getMaxTransactionDate(
                        salesOrder
                    );

                    if (maxTransactionDate >= startOfCurrentMonth) {
                        // this should always be the end date of the month
                        // comparision would always be with the start of the month.
                        nextArrangementEffectiveDate = startOfCurrentMonth;
                    }
                }
            }
        }

        if (
            salesOrder.revenueStartDate &&
            Period.isAfter(
                salesOrder.revenueStartDate,
                nextArrangementEffectiveDate
            )
        ) {
            // revenue start date is in the future
            nextArrangementEffectiveDate = salesOrder.revenueStartDate;
        }

        salesOrder.newRevenueArrangement.effectiveDate = nextArrangementEffectiveDate;
        salesOrder.newRevenueArrangement.postingDate = nextArrangementEffectiveDate;
    }

    // Add a rev arrangement item for all new salesOrderItems.
    salesOrder.newRevenueArrangement &&
        salesOrder.salesOrderItem.forEach((soItem) => {
            let raItem = salesOrder.newRevenueArrangement.revenueArrangementItem.find(
                (raItem) => raItem.salesOrderItemId === soItem.id
            );
            if (!raItem) {
                salesOrder.newRevenueArrangement.revenueArrangementItem.push(
                    newRevenueArrangementItem(
                        soItem,
                        salesOrder.newRevenueArrangement.id
                    )
                );
            }
        });

    // No arrangements yet.
    if (salesOrder.revenueArrangement.length === 0) return;

    let foundSSP = true;

    Utils.resetRuntime(salesOrder);

    const { id: activeRaId } =
        salesOrder.newRevenueArrangement ||
        salesOrder.currentRevenueArrangement;

    try {
        salesOrder.revenueArrangement.forEach((revenueArrangement, raIndex) => {
            let foundNonZeroSSP = false;

            revenueArrangement.revenueArrangementItem.forEach((item) => {
                let { salesOrderItem } = item;

                item.effectiveDate = revenueArrangement.effectiveDate;
                item.endDate = revenueArrangement.endDate;

                // Populate custom client attribute;
                populateClientAttributes(
                    clientAttributes,
                    ["revenueArrangementItem"],
                    {
                        revenueArrangementItem: item,
                        salesOrder
                    }
                );

                // SSP is already loaded from snapshot.
                if (!item.ssp) {
                    selectSSP(clientReferenceData, item, salesOrder);

                    // Client has explicitly set standaone selling price of this item.
                    // So so standalonePrice gets overridden.
                    if (
                        item.ssp &&
                        (item.salesOrderItem.standaloneSellingPrice ||
                            item.salesOrderItem.isDeleted)
                    ) {
                        let override_ssp = Utils.copy(item.ssp);
                        override_ssp.id = shortid.generate();
                        override_ssp.standalonePrice =
                            item.salesOrderItem.isDeleted === true
                                ? 0
                                : item.salesOrderItem.standaloneSellingPrice;
                        item.ssp = override_ssp;
                    }

                    if (
                        !item.ssp &&
                        item.salesOrderItem.standaloneSellingPrice
                    ) {
                        const override_ssp = Utils.copy(
                            defaultSSPForProductCode(shortid.generate())
                        );
                        override_ssp.attributes["productCode"] = "TERMINATE";

                        if (item.salesOrderItem.product.recurring == true) {
                            override_ssp.recognitionRuleId =
                                RecognitionRule.RATABLE;
                            delete override_ssp.recognizedOn;
                        }

                        override_ssp.standalonePrice =
                            item.salesOrderItem.standaloneSellingPrice;
                        override_ssp.sspType = "Revenue";

                        item.ssp = override_ssp;
                    }

                    const autoCreateSSP = getAutoCreateSSP(orgConfig);

                    if (!item.ssp && autoCreateSSP == true) {
                        const {
                            fetchBusinessKeyValues
                        } = require("./SalesOrderVisitor");

                        let sspFieldsConfig = orgConfig.find(
                            (cfg) => cfg.id === "properties/ssp-fields"
                        );
                        let sspFields =
                            (sspFieldsConfig && sspFieldsConfig.value) || [];
                        const sspKeyBuilder = Utils.createCompositeKey(
                            sspFields
                        );

                        const missingSSP = fetchBusinessKeyValues(
                            salesOrder,
                            salesOrderItem,
                            sspFields
                        );

                        const override_ssp = Utils.copy(
                            defaultSSPForProductCode(
                                shortid.generate(),
                                missingSSP
                            )
                        );
                        override_ssp.id = Utils.searchValue(
                            sspKeyBuilder(missingSSP)
                        );

                        override_ssp.standalonePrice = { type: "AS_IS" };

                        if (
                            AccountingUtils.isDiscountProduct(
                                item.salesOrderItem.product
                            )
                        ) {
                            override_ssp.recognitionRuleId =
                                RecognitionRule.POINT_IN_TIME;
                            override_ssp.standalonePrice = 0;
                        } else {
                            if (item.salesOrderItem.product.recurring == true) {
                                override_ssp.recognitionRuleId =
                                    RecognitionRule.RATABLE;
                                delete override_ssp.recognizedOn;
                            }
                        }

                        const meteredCfg = orgConfig.find(
                            (cfg) =>
                                cfg.id ===
                                "properties/metered_connection_enabled"
                        );
                        const isMeteredEnabled =
                            (meteredCfg && meteredCfg.value) || false;

                        if (
                            isMeteredEnabled &&
                            AccountingUtils.isMeteredProduct(
                                item.salesOrderItem.product
                            )
                        ) {
                            override_ssp.recognitionRuleId =
                                RecognitionRule.PROPORTIONAL_PERFORMANCE;
                        }

                        override_ssp.sspType = "Revenue";

                        item.ssp = override_ssp;

                        // Save the auto created SSP to be saved later.
                        if (!salesOrder.autoCreatedSSP)
                            salesOrder.autoCreatedSSP = {};
                        salesOrder.autoCreatedSSP[item.ssp.id] = item.ssp;
                    }
                }

                if (!item.ssp) {
                    errors.add(Codes.TRAN_11, {
                        salesOrder,
                        salesOrderItem,
                        revenueArrangementItem: item
                    });
                    foundSSP = false;
                }

                if (
                    item.ssp &&
                    salesOrder.contractId &&
                    isCreditNoteItem(item.salesOrderItem) &&
                    disableCnAllocation
                ) {
                    let override_ssp = Utils.copy(item.ssp);
                    override_ssp.standalonePrice = { type: "STANDALONE" };
                    item.ssp = override_ssp;
                }

                const soi = getSOI(
                    salesOrder,
                    salesOrderItem.rootId || salesOrderItem.id
                );

                if (
                    item.ssp &&
                    // && (!item.deliveryStartDate || soi.startDate !== item.deliveryStartDate)
                    (item.isActive == undefined || item.isActive == false)
                ) {
                    const cfConfig = orgConfig.find(
                        (config) => config.id == "customFields/attributeMapping"
                    );
                    const cFields =
                        cfConfig &&
                        cfConfig.value &&
                        Object.values(cfConfig.value);

                    const d = orgConfig.find(
                        (config) =>
                            config.id == "properties/ratable_end_date_inclusive"
                    );
                    const endDateInclusive = (d && d.value) || false;

                    const d1 = orgConfig.find(
                        (config) =>
                            config.id ==
                            "properties/dereference_rule_has_priority"
                    );
                    const dereferenceRuleHasPriority =
                        (d1 && d1.value) || false;

                    const d2 = orgConfig.find(
                        (config) =>
                            config.id ===
                            "properties/dereference_rule_has_priority/disable-product"
                    );
                    const disableProduct = (d2 && d2.value) || false;

                    populateDeliveryDates(
                        salesOrder,
                        revenueArrangement,
                        soi,
                        item.ssp,
                        item,
                        errors,
                        cfConfig && cFields,
                        endDateInclusive,
                        dereferenceRuleHasPriority,
                        isApiCall,
                        disableProduct
                    );
                }

                soi.listPrice = Utils.isNumber(soi.listPrice)
                    ? soi.listPrice
                    : computeTermListPrice(
                          soi.product,
                          soi.startDate,
                          soi.endDate
                      );

                const useProductListPriceConfig = orgConfig.find(
                    (cfg) => cfg.id === "properties/enable_list_price_calc"
                );
                const useProductListPrice =
                    (useProductListPriceConfig &&
                        useProductListPriceConfig.value) ||
                    false;

                // If the sales order has prospective modification, recompute the listPrice
                if (
                    useProductListPrice &&
                    soi.isListPriceProRated &&
                    revenueArrangement.modificationType ==
                        ModificationType.PROSPECTIVE &&
                    !ItemType.isFrozen(item.itemType)
                ) {
                    let startDate = item.deliveryStartDate;
                    if (
                        Period.isDateBetween(
                            revenueArrangement.modificationDate,
                            startDate,
                            item.deliveryEndDate,
                            true,
                            endDateInclusive
                        )
                    ) {
                        startDate = revenueArrangement.modificationDate;
                    }
                    soi.listPrice = AccountingUtils.computeListPrice(
                        soi.product,
                        startDate,
                        item.deliveryEndDate
                    );
                }

                // When
                if (
                    useProductListPrice &&
                    item.ssp &&
                    item.ssp.recognitionRuleId ===
                        RecognitionRule.PROPORTIONAL_PERFORMANCE
                ) {
                    soi.listPrice = soi.product.listPriceAmount || 0;
                }
                soi.extendedListPrice = soi.quantity * soi.listPrice;
                // Populate listPrice and extended listPrice

                populateAnnualExtendedSalePrice(soi);
                //annual-ize sales price

                const _soi = getSOI(
                    salesOrder,
                    salesOrderItem.rootId || salesOrderItem.id
                );
                if (item.ssp && !_soi.isActive) {
                    const customFieldsConfig = orgConfig.find(
                        (config) =>
                            config.id === "customFields/attributeMapping"
                    );
                    errors.add(Codes.TRAN_30, {
                        salesOrder,
                        salesOrderItem: _soi,
                        customFields:
                            customFieldsConfig && customFieldsConfig.value
                    });
                }

                if (
                    item.ssp &&
                    item.ssp.recognitionRuleId !== RecognitionRule.POINT_IN_TIME
                ) {
                    const soi = getSOI(
                        salesOrder,
                        salesOrderItem.rootId || salesOrderItem.id
                    );

                    if (soi.endDate && soi.startDate) {
                        let duration = Utils.getMonthDiff(
                            soi.startDate,
                            soi.endDate,
                            false
                        );
                        const d3 = orgConfig.find(
                            (cfg) => cfg.id === "properties/max-contract-years"
                        );
                        const maxContractYears =
                            (d3 && d3.value && Number(d3.value)) || 15;

                        if (duration > maxContractYears * 12) {
                            errors &&
                                errors.add(Codes.TRAN_15, {
                                    salesOrder,
                                    salesOrderItem,
                                    maxContractYears
                                });
                        } else if (duration < 0) {
                            errors &&
                                errors.add(Codes.TRAN_18, {
                                    salesOrder,
                                    salesOrderItem
                                });
                        }
                    }
                }
            });

            // This call is at revenueArrangement level and not at raItem level
            const previousRevenueArrangement =
                removedRevenueArrangement ||
                salesOrder.currentRevenueArrangement;
            if (
                revenueArrangement.modificationType ==
                    ModificationType.PROSPECTIVE &&
                salesOrder.newRevenueArrangement &&
                salesOrder.newRevenueArrangement.id == revenueArrangement.id &&
                previousRevenueArrangement
            ) {
                applyProspectiveModification(
                    salesOrder,
                    revenueArrangement,
                    previousRevenueArrangement,
                    orgConfig,
                    orgConfigByKey,
                    calendarConfig
                );
            }

            if (psdByProductCode && revenueArrangement.id == activeRaId) {
                PSHelper.populateDeliveryLogs(
                    revenueArrangement,
                    salesOrder,
                    orgConfig,
                    errors
                );
            }

            if (foundSSP) {
                let totalESP = 0;
                let standaloneESP = 0;

                let sspType = (item) =>
                    item.ssp.standalonePrice &&
                    typeof item.ssp.standalonePrice === "object" &&
                    item.ssp.standalonePrice.type == "RESIDUAL"
                        ? "RESIDUAL"
                        : "NON_RESIDUAL";

                revenueArrangement.revenueArrangementItem.sort(
                    Comparator.getComparator(
                        [sspType],
                        [Comparator.forType("string")],
                        false
                    )
                );

                const reasonCodeConfig = orgConfig.find(
                    (cfg) => cfg.id == "properties/expense/reasoncodes"
                );
                const reasonCodeValues =
                    (reasonCodeConfig && reasonCodeConfig.value) || [];

                let totalBooking = 0;
                let totalPoCnBooking = 0;
                let useLocalTotalBooking = false;
                let filteredRaItems;
                if (reasonCodeValues.length) {
                    // we have reason code enabled, we need to re-calulcate the totalBooking by excluding the items which have reason codes.
                    filteredRaItems = revenueArrangement.revenueArrangementItem.filter(
                        (item) =>
                            !AccountingUtils.itemHasReasonCode(item, orgConfig)
                    );
                    useLocalTotalBooking = filteredRaItems.length > 0;
                }
                if (
                    revenueArrangement.modificationType ==
                    ModificationType.PROSPECTIVE
                ) {
                    // salesorder has prospective modification, exclude frozen items from calculating totalBooking
                    filteredRaItems =
                        filteredRaItems ||
                        revenueArrangement.revenueArrangementItem;
                    useLocalTotalBooking = true;
                    filteredRaItems = filteredRaItems.filter(
                        (item) => !ItemType.isFrozen(item.itemType)
                    );
                }

                if (useLocalTotalBooking) {
                    filteredRaItems.forEach((item) => {
                        const {
                            itemType,
                            unrecognizedRevenue,
                            salesOrderItem
                        } = item;
                        if (itemType == ItemType.SPLIT_UNRECOGNIZED) {
                            totalBooking += unrecognizedRevenue;
                        } else {
                            totalBooking += salesOrderItem.extendedSalePrice;
                        }
                    });
                }

                let poCnParentItemIds = new Set();
                for (const item of revenueArrangement.revenueArrangementItem) {
                    const { salesOrderItem, itemType } = item;
                    if (
                        salesOrderItem.parentSalesOrderItemId &&
                        salesOrderItem.isCreditNote &&
                        salesOrderItem.referenceLineItemId &&
                        !ItemType.isFrozen(itemType)
                    ) {
                        poCnParentItemIds.add(
                            salesOrderItem.parentSalesOrderItemId
                        );
                    }
                }

                // iterating over non credit note line items.
                revenueArrangement.revenueArrangementItem.forEach((item) => {
                    let { salesOrderItem } = item;

                    if (Utils.isEmpty(item.estimatedSalePrice)) {
                        item.estimatedSalePrice = getStandAlonePrice(
                            item,
                            revenueArrangement,
                            salesOrder,
                            orgConfig
                        );
                    }

                    if (
                        Utils.isNumber(item.estimatedSalePrice) &&
                        item.estimatedSalePrice != 0 &&
                        item.ssp.standalonePrice &&
                        item.ssp.standalonePrice.type !== "STANDALONE"
                    ) {
                        foundNonZeroSSP = true;
                    }

                    if (Utils.isEmpty(item.totalBooking)) {
                        item.totalBooking = salesOrder.totalBooking;
                    }

                    if (useLocalTotalBooking) {
                        // we can't use sales order total booking since the above variable
                        // has incorporated reason code expense as well, which we can't use.
                        item.totalBooking = totalBooking;
                    }

                    item.actualSalePrice = salesOrderItem.actualSalePrice;
                    item.extendedSalePrice = salesOrderItem.extendedSalePrice;

                    item.extendedEstimatedSalePrice =
                        item.estimatedSalePrice * salesOrderItem.quantity;

                    // do not include frozen items to calculate totalESP
                    if (ItemType.isFrozen(item.itemType)) {
                        return;
                    }

                    if (
                        item.ssp.standalonePrice &&
                        item.ssp.standalonePrice.type == "STANDALONE"
                    ) {
                        // We separated STANDALONE esp thkning about the significant financing component.
                        // That should not have been handled with STANDALONE.
                        if (item.itemType == ItemType.SPLIT_UNRECOGNIZED) {
                            standaloneESP += item.unrecognizedRevenue;
                        } else {
                            standaloneESP += item.extendedEstimatedSalePrice;
                        }
                    } else if (
                        (!salesOrderItem.referenceLineItemId ||
                            (salesOrderItem.referenceLineItemId &&
                                item.ssp.standalonePrice &&
                                item.ssp.standalonePrice.type == "AS_IS")) &&
                        !AccountingUtils.itemHasReasonCode(item, orgConfig)
                    ) {
                        // if this item has reason code, we can't include this in the totalESP for calc [REV-8406]
                        totalESP += item.extendedEstimatedSalePrice;
                    }

                    if (
                        poCnParentItemIds.has(
                            salesOrderItem.rootId || salesOrderItem.id
                        )
                    ) {
                        totalPoCnBooking += salesOrderItem.extendedSalePrice;
                    }

                    if (
                        !item.recognitionRuleId ==
                            RecognitionRule.PERCENT_OF_COMPLETE &&
                        Utils.isEmpty(salesOrderItem.estimatedTotalCost)
                    ) {
                        errors.add(Codes.TRAN_11, {
                            salesOrder,
                            salesOrderItem,
                            revenueArrangementItem: item
                        });
                        foundSSP = false;
                    }
                });

                // REV-10421 any sales order item that has a referenceLineItemId, it means it is a credit note line item.
                // we want to process non credit note line items first and then process credit note lines
                // since credit note lines depend on ssp value (estimatedSalePrice) of non credit note line item

                // NOTE: if we have reason code, and that item has reason code, bad debt expense work will happen on this
                // we are not chaning this right now.
                const creditNoteRevenueArrangementItem = getCreditNoteLineItems(
                    revenueArrangement.revenueArrangementItem,
                    orgConfig
                );

                // all non credit note lines. The ones that have reason codes, they will be returned even though they are credit notes.
                const nonCreditNoteRevenueArrangementItem = getNonCreditNoteLineItems(
                    revenueArrangement.revenueArrangementItem,
                    orgConfig
                );

                const raiBySalesOrderItemId = {};
                const poTotalESPByParentSalesOrderItemId = {};
                // maintain totalESP of po items by their parentSalesOrderItemId
                revenueArrangement.revenueArrangementItem.forEach((rai) => {
                    // for a prospective modified order, do not include the split frozen item
                    if (rai.itemType == ItemType.SPLIT_FROZEN) return;
                    raiBySalesOrderItemId[
                        rai.salesOrderItem.rootId || rai.salesOrderItem.id
                    ] = rai;
                    const parentSalesOrderItemId =
                        rai.salesOrderItem.parentSalesOrderItemId;
                    if (parentSalesOrderItemId) {
                        let totalESP =
                            poTotalESPByParentSalesOrderItemId[
                                parentSalesOrderItemId
                            ] || 0;
                        totalESP += rai.extendedEstimatedSalePrice;
                        poTotalESPByParentSalesOrderItemId[
                            parentSalesOrderItemId
                        ] = totalESP;
                    }
                });

                const nonCreditNoteRAIByReferenceLineItemId = {};
                const creditNoteRAIByReferenceLineItemId = {};
                // maintaing credit note and non credit note line item rai by item id for which credit note came.
                if (creditNoteRevenueArrangementItem.length) {
                    creditNoteRevenueArrangementItem.forEach((rai) => {
                        if (
                            !creditNoteRAIByReferenceLineItemId[
                                rai.salesOrderItem.referenceLineItemId
                            ]
                        ) {
                            creditNoteRAIByReferenceLineItemId[
                                rai.salesOrderItem.referenceLineItemId
                            ] = [];
                        }
                        creditNoteRAIByReferenceLineItemId[
                            rai.salesOrderItem.referenceLineItemId
                        ].push(rai);
                    });

                    nonCreditNoteRevenueArrangementItem.forEach((rai) => {
                        nonCreditNoteRAIByReferenceLineItemId[
                            rai.salesOrderItem.rootId || rai.salesOrderItem.id
                        ] = rai;
                    });
                }

                // process credit notes now.
                // TODO: Remove code duplication from creditNoteRevenueArrangementItem and nonCreditNoteRevenueArrangementItem
                creditNoteRevenueArrangementItem.forEach((item) => {
                    item.deliveryStartDate =
                        item.deliveryStartDate &&
                        formatDate(item.deliveryStartDate);
                    item.deliveryEndDate =
                        item.deliveryEndDate &&
                        formatDate(item.deliveryEndDate);

                    item.goLiveDate =
                        item.goLiveDate && formatDate(item.goLiveDate);

                    if (!item.deliveryStartDate && item.goLiveDate) {
                        item.deliveryStartDate = item.goLiveDate;
                    } else if (item.deliveryStartDate && !item.goLiveDate) {
                        item.goLiveDate = item.deliveryStartDate;
                    }

                    if (
                        !item.recognitionRule &&
                        item.ssp &&
                        item.ssp.recognitionRuleId
                    ) {
                        const ruleKey = Object.keys(RecognitionRule).find(
                            (ruleKey) => {
                                const value = RecognitionRule[ruleKey];
                                if (value == item.ssp.recognitionRuleId) {
                                    return true;
                                }
                            }
                        );

                        item.recognitionRule = {
                            id: item.ssp.recognitionRuleId,
                            name: ruleKey
                        };

                        item.recognitionRuleId = item.ssp.recognitionRuleId;
                    }

                    // if (item.ssp && item.ssp.standalonePrice != undefined) {
                    //     // for credit notes, ssp's standalone price is always 0
                    //     item.ssp.standalonePrice = 0
                    //     item.sspCalculation.standalonePrice = 0
                    // }

                    // NOTE: The calculation for product revenue of credit note is
                    // % of Toal Invoice Amount (credit line) * relativeSSPProportion (non credit line) * total booking

                    const parentSalesOrderItemId =
                        item.salesOrderItem.parentSalesOrderItemId;
                    if (parentSalesOrderItemId) {
                        const parentItem =
                            raiBySalesOrderItemId[parentSalesOrderItemId];
                        item.percentOfTotalInvoiceLineItem = 0;
                        const cnRelativeSSPProportion =
                            item.extendedEstimatedSalePrice /
                            poTotalESPByParentSalesOrderItemId[
                                parentSalesOrderItemId
                            ];
                        item.productRevenue =
                            parentItem.extendedSalePrice *
                            cnRelativeSSPProportion;
                    } else if (
                        !foundNonZeroSSP ||
                        (AccountingUtils.itemHasReasonCode(item, orgConfig) &&
                            !item.salesOrderItem.poProcessed &&
                            ![
                                "SIMPLE_PERCENT_NET_RANGE",
                                "PERCENT_NET_RANGE"
                            ].includes(item.ssp.standalonePrice.type))
                    ) {
                        // any item that has a reason code and
                        // this item is not such that it has child products (PO products) see SalesOrderPreprocessor
                        // and this item's ssp type is not one of percentage range.
                        // then the product revenue is always extendedSalePrice.
                        // please see ticket: REV-8406
                        item.productRevenue = item.extendedSalePrice;
                    } else if (
                        item.ssp.standalonePrice &&
                        item.ssp.standalonePrice.type == "STANDALONE"
                    ) {
                        // We separated STANDALONE esp thinking about the significant financing component.
                        // That should not have been handled with STANDALONE.
                        item.percentOfTotalInvoiceLineItem = 0;
                        if (item.itemType == ItemType.SPLIT_UNRECOGNIZED) {
                            item.productRevenue = item.unrecognizedRevenue;
                        } else {
                            item.productRevenue =
                                item.extendedEstimatedSalePrice;
                        }
                    } else {
                        const nonCreditNoteRAI =
                            nonCreditNoteRAIByReferenceLineItemId[
                                item.salesOrderItem.referenceLineItemId
                            ];
                        let percentOfTotalInvoiceLineItem =
                            item.extendedSalePrice /
                            (item.extendedSalePrice +
                                nonCreditNoteRAI.extendedSalePrice);

                        if (
                            isNaN(percentOfTotalInvoiceLineItem) ||
                            !isFinite(percentOfTotalInvoiceLineItem)
                        ) {
                            percentOfTotalInvoiceLineItem = -1;
                        }

                        // non credit note line percentage
                        item.percentOfTotalInvoiceLineItem = percentOfTotalInvoiceLineItem;

                        let nonCreditNoteLineRelativeSSPProportion =
                            nonCreditNoteRAI.extendedEstimatedSalePrice /
                                totalESP || 0;

                        const totalBookingToAllocate =
                            item.totalBooking -
                            standaloneESP -
                            totalPoCnBooking;

                        item.productRevenue =
                            nonCreditNoteLineRelativeSSPProportion *
                            percentOfTotalInvoiceLineItem *
                            totalBookingToAllocate;

                        // If totalSSP nets to Zero but the items are non zero
                        if (
                            item.ssp.standalonePrice &&
                            item.ssp.standalonePrice.type == "AS_IS" &&
                            foundNonZeroSSP &&
                            totalESP != 0 &&
                            item.productRevenue == 0
                        ) {
                            item.productRevenue =
                                item.extendedEstimatedSalePrice || 0;
                        }
                    }

                    item.relativeSalePricePercent =
                        item.productRevenue / item.totalBooking || 0;

                    if (
                        options.createRevenuePlan &&
                        ((revenueArrangement.state == "New" &&
                            item.recognitionRule &&
                            !item.hasManualUpdates) ||
                            !item.plan ||
                            item.plan.length == 0)
                    ) {
                        try {
                            buildDefaultRevenuePlan(
                                item,
                                salesOrder,
                                orgConfig,
                                customCode,
                                errors
                            );
                        } catch (e) {
                            throw e;
                        }
                    }

                    if (
                        item.plan &&
                        (item.recognitionRule.id ===
                            RecognitionRule.PROPORTIONAL_PERFORMANCE ||
                            item.recognitionRule.id ===
                                RecognitionRule.PERCENT_OF_COMPLETE)
                    ) {
                        buildPSPlan(
                            item,
                            salesOrder,
                            revenueArrangement,
                            errors,
                            calendarConfig,
                            orgConfig,
                            orgConfigByKey
                        );
                    }

                    if (item.plan && unearnedByValue !== "customer") {
                        addBillingPeriodsToPlan(
                            item.plan,
                            salesOrder,
                            calendarConfig
                        );
                    }
                });

                // TODO: Remove code duplication from creditNoteRevenueArrangementItem and nonCreditNoteRevenueArrangementItem
                nonCreditNoteRevenueArrangementItem.forEach(
                    (item, raiIndex) => {
                        item.deliveryStartDate =
                            item.deliveryStartDate &&
                            formatDate(item.deliveryStartDate);
                        item.deliveryEndDate =
                            item.deliveryEndDate &&
                            formatDate(item.deliveryEndDate);

                        item.goLiveDate =
                            item.goLiveDate && formatDate(item.goLiveDate);

                        if (!item.deliveryStartDate && item.goLiveDate) {
                            item.deliveryStartDate = item.goLiveDate;
                        } else if (item.deliveryStartDate && !item.goLiveDate) {
                            item.goLiveDate = item.deliveryStartDate;
                        }

                        if (
                            !item.recognitionRule &&
                            item.ssp &&
                            item.ssp.recognitionRuleId
                        ) {
                            const ruleKey = Object.keys(RecognitionRule).find(
                                (ruleKey) => {
                                    const value = RecognitionRule[ruleKey];
                                    if (value == item.ssp.recognitionRuleId) {
                                        return true;
                                    }
                                }
                            );

                            item.recognitionRule = {
                                id: item.ssp.recognitionRuleId,
                                name: ruleKey
                            };

                            item.recognitionRuleId = item.ssp.recognitionRuleId;
                        }

                        if (ItemType.isFrozen(item.itemType)) {
                            return;
                        }

                        const totalBookingToAllocate =
                            item.totalBooking -
                            standaloneESP -
                            totalPoCnBooking;

                        if (
                            !foundNonZeroSSP ||
                            (AccountingUtils.itemHasReasonCode(
                                item,
                                orgConfig
                            ) &&
                                !item.salesOrderItem.poProcessed &&
                                ![
                                    "SIMPLE_PERCENT_NET_RANGE",
                                    "PERCENT_NET_RANGE"
                                ].includes(item.ssp.standalonePrice.type))
                        ) {
                            // any item that has a reason code and
                            // this item is not such that it has child products (PO products) see SalesOrderPreprocessor
                            // and this item's ssp type is not one of percentage range.
                            // then the product revenue is always extendedSalePrice.
                            // please see ticket: REV-8406
                            item.productRevenue = item.extendedSalePrice;
                        } else if (
                            item.ssp.standalonePrice &&
                            item.ssp.standalonePrice.type == "STANDALONE"
                        ) {
                            // We separated STANDALONE esp thinking about the significant financing component.
                            // That should not have been handled with STANDALONE.
                            if (item.itemType == ItemType.SPLIT_UNRECOGNIZED) {
                                item.productRevenue = item.unrecognizedRevenue;
                            } else {
                                item.productRevenue =
                                    item.extendedEstimatedSalePrice;
                            }
                        } else if (
                            creditNoteRevenueArrangementItem.length &&
                            creditNoteRAIByReferenceLineItemId[
                                item.salesOrderItem.rootId ||
                                    item.salesOrderItem.id
                            ] &&
                            creditNoteRAIByReferenceLineItemId[
                                item.salesOrderItem.rootId ||
                                    item.salesOrderItem.id
                            ].length
                        ) {
                            // we have credit notes
                            // the way we calculate product revenue is different from when we don't have credit notes.
                            const creditNoteRais =
                                creditNoteRAIByReferenceLineItemId[
                                    item.salesOrderItem.rootId ||
                                        item.salesOrderItem.id
                                ];

                            let percentOfTotalInvoiceLineItem = 0;
                            for (const creditNoteRai of creditNoteRais) {
                                percentOfTotalInvoiceLineItem +=
                                    creditNoteRai.percentOfTotalInvoiceLineItem;
                            }
                            let relativeSSPProportion =
                                item.extendedEstimatedSalePrice / totalESP || 0;

                            // non credit note line item is always considered 100%
                            item.percentOfTotalInvoiceLineItem =
                                1 + Math.abs(percentOfTotalInvoiceLineItem);

                            item.productRevenue =
                                relativeSSPProportion *
                                item.percentOfTotalInvoiceLineItem *
                                totalBookingToAllocate;

                            // If totalSSP nets to Zero but the items are non zero
                            if (
                                item.ssp.standalonePrice &&
                                item.ssp.standalonePrice.type == "AS_IS" &&
                                foundNonZeroSSP &&
                                totalESP != 0 &&
                                item.productRevenue == 0
                            ) {
                                item.productRevenue =
                                    item.extendedEstimatedSalePrice;
                            }
                        } else {
                            // non credit note calculations
                            let relativeSSPProportion =
                                item.extendedEstimatedSalePrice / totalESP || 0;
                            item.productRevenue =
                                relativeSSPProportion * totalBookingToAllocate;
                        }

                        item.relativeSalePricePercent =
                            item.productRevenue / item.totalBooking || 0;

                        // If totalSSP nets to Zero but the items are non zero
                        if (foundNonZeroSSP && totalESP == 0) {
                            item.productRevenue =
                                item.extendedEstimatedSalePrice;
                            if (item.itemType == ItemType.SPLIT_UNRECOGNIZED) {
                                item.productRevenue = item.unrecognizedRevenue;
                            }
                        }

                        if (
                            options.createRevenuePlan &&
                            ((revenueArrangement.state == "New" &&
                                item.recognitionRule &&
                                !item.hasManualUpdates) ||
                                !item.plan ||
                                item.plan.length == 0)
                        ) {
                            try {
                                buildDefaultRevenuePlan(
                                    item,
                                    salesOrder,
                                    orgConfig,
                                    customCode,
                                    errors
                                );
                            } catch (e) {
                                throw e;
                            }
                        }

                        if (
                            item.plan &&
                            (item.recognitionRule.id ===
                                RecognitionRule.PROPORTIONAL_PERFORMANCE ||
                                item.recognitionRule.id ===
                                    RecognitionRule.PERCENT_OF_COMPLETE)
                        ) {
                            buildPSPlan(
                                item,
                                salesOrder,
                                revenueArrangement,
                                errors,
                                calendarConfig,
                                orgConfig,
                                orgConfigByKey
                            );
                        }

                        if (item.plan && unearnedByValue !== "customer") {
                            addBillingPeriodsToPlan(
                                item.plan,
                                salesOrder,
                                calendarConfig
                            );
                        }
                    }
                );
            }
        });

        //populate the annual booking amount for the order.
        salesOrder.totalAnnualBooking = salesOrder.salesOrderItem.reduce(
            (booking, salesOrderItem) =>
                (booking += salesOrderItem.annualExtendedSalePrice),
            0.0
        );

        // Populate custom client attribute;
        populateClientAttributes(clientAttributes, ["salesOrder"], {
            salesOrder
        });

        ExpenseArrangementHelper.processExpense(
            clientReferenceData,
            salesOrder,
            errors,
            orgConfig,
            options
        );
    } catch (e) {
        const err = `Ran in to errors while populating revenue arrangement for so ${salesOrder.id} with error: ${e.message}, ${e.stack}`;
        throw new Error(err);
    } finally {
        Utils.disposeRuntime(salesOrder);
    }
}

const snapshotKeys = [
    "salesOrderItem",
    "ssp",
    "attributes",
    "estimatedSalePrice",
    "totalBooking"
];

// Restore saved snapshot for revenue arrangement item, if available.
const restoreItemSnapshots = (revenueArrangementItem) => {
    let _snapshotKeys = [
        "salesOrderItem",
        "attributes",
        "estimatedSalePrice",
        "totalBooking"
    ];
    if (revenueArrangementItem.snapshots) {
        _snapshotKeys = snapshotKeys;
    }

    // Clear all prior snapshots.
    _snapshotKeys.forEach((key) => {
        delete revenueArrangementItem[key];
    });

    revenueArrangementItem.snapshots &&
        Object.keys(revenueArrangementItem.snapshots).forEach((key) => {
            if (snapshotKeys.includes(key)) {
                revenueArrangementItem[key] =
                    revenueArrangementItem.snapshots[key];
            }
        });
};

export function _nextVersion(salesOrderItemId) {
    var myRegexp = /_v([\d]+)$/;
    var match = myRegexp.exec(salesOrderItemId);
    var priorVersion = (match && match[1]) || 0;
    return Number(priorVersion) + 1;
}

export function getSalesOrderItemRootId(salesOrderItemId) {
    let _myRegexp = /_v([\d]+)$/;
    return salesOrderItemId.replace(_myRegexp, "");
}

export const createSnapshotsForCurrentArrangement = (salesOrder) => {
    return;
};

const getMaxTransactionDate = (salesOrder) => {
    let maxTransactionDate;

    salesOrder.salesOrderItem.forEach((item) => {
        if (!maxTransactionDate) {
            maxTransactionDate = item.transactionDate;
        } else if (item.transactionDate > maxTransactionDate) {
            maxTransactionDate = item.transactionDate;
        }
    });

    return maxTransactionDate;
};

const getMinTransactionDate = (salesOrder) => {
    let minTransactionDate = salesOrder.salesOrderItem[0].transactionDate;

    salesOrder.salesOrderItem.forEach((item) => {
        if (!minTransactionDate) {
            minTransactionDate = item.transactionDate;
        } else if (item.transactionDate < minTransactionDate) {
            minTransactionDate = item.transactionDate;
        }
    });

    return minTransactionDate;
};

export const newRevenueArrangement = (salesOrder) => {
    const orderDate = Utils.formatDate(salesOrder.orderDate);

    let revenueStartDate =
        salesOrder.revenueStartDate != undefined
            ? Utils.formatDate(salesOrder.revenueStartDate)
            : orderDate;
    // setting revenueStartDate to salesOrder.revenueStartDate otherwaise defaulting it to orderDate

    let revenueArrangement = {};
    revenueArrangement.effectiveDate = revenueStartDate;
    revenueArrangement.isRevenueDelayed = salesOrder.isRevenueDelayed;
    revenueArrangement.salesOrderId = salesOrder.id;
    revenueArrangement.id = shortid.generate();
    revenueArrangement.new = true;
    revenueArrangement.revenueArrangementItem = salesOrder.salesOrderItem.map(
        (item, index) => {
            return newRevenueArrangementItem(item, revenueArrangement.id);
        }
    );
    revenueArrangement.state = "New";

    if (salesOrder.orderType == "Modify") {
        if (salesOrder.modificationType == ModificationType.PROSPECTIVE) {
            revenueArrangement.modificationType = ModificationType.PROSPECTIVE;
            revenueArrangement.modificationDate = salesOrder.modificationDate;
        } else {
            revenueArrangement.modificationType =
                ModificationType.RETROSPECTIVE;
        }
    }

    if (salesOrder.expenseItems && salesOrder.expenseItems.length) {
        revenueArrangement.expenseItems = Utils.cloneDeep(
            salesOrder.expenseItems
        );
    } else {
        revenueArrangement.expenseItems = [];
    }

    salesOrder.revenueArrangement.push(revenueArrangement);
    if (!salesOrder.revenueArrangementIds) {
        salesOrder.revenueArrangementIds = [];
    }
    salesOrder.revenueArrangementIds.push(revenueArrangement.id);
    revenueArrangement.jobId = salesOrder.jobId;
    salesOrder.newRevenueArrangement = revenueArrangement;
};

export const newRevenueArrangementItem = (
    salesOrderItem,
    revenueArrangementId,
    itemType = ItemType.UNCHANGED
) => {
    return {
        id: shortid.generate(),
        salesOrderItemId: salesOrderItem.id,
        salesOrderItem: salesOrderItem,
        itemType,
        expenseItems: [],
        revenueArrangementId: revenueArrangementId,
        liabilityAccountId: salesOrderItem.deferredRevenueAccountNumber,
        revenueAccountId: salesOrderItem.revenueAccountNumber,
        contraRevenueAccountId: salesOrderItem.contraRevenueAccountNumber,
        deferredCommissionAccountId:
            salesOrderItem.deferredCommissionAccountNumber,
        commissionAmortizationAccountId: salesOrderItem.commissionAccountNumber,
        contraCommissionExpenseAccountId:
            salesOrderItem.contraCommissionExpenseAccountNumber,
        commissionAccumulatedAmortizationAccountId:
            salesOrderItem.commissionAccumulatedAmortizationAccountNumber
    };
};

/* -------
   Default Revenue Plan
   -------  */
export function buildDefaultRevenuePlan(
    revenueArrangementItem,
    salesOrder,
    orgConfig,
    customCode,
    errors
) {
    if (!revenueArrangementItem || !revenueArrangementItem.recognitionRule)
        throw new Error("Missing revenue arrangement item or rule");

    const d = orgConfig.find((config) => config.id === "properties/calendar");
    const calendarConfig = d && d.value && d.value.config;

    let productRevenue = revenueArrangementItem.productRevenue;

    const findRevenueArrangementItemByProductCode = (
        salesOrder,
        productId,
        revenueArrangementId
    ) => {
        const revenueArrangement = salesOrder.revenueArrangement.find(
            (rai) => rai.id === revenueArrangementId
        );
        const rai = revenueArrangement.revenueArrangementItem.find(
            (revenueArrangementItem) =>
                revenueArrangementItem.salesOrderItem.productId === productId
        );
        return rai;
    };

    if (salesOrder.revenueArrangement) {
        const currentRevenueArrangement = salesOrder.revenueArrangement.find(
            (ra) => ra.id == revenueArrangementItem.revenueArrangementId
        );

        if (currentRevenueArrangement.isRevenueDelayed) {
            productRevenue = 0;
        }
    }

    switch (revenueArrangementItem.recognitionRule.id) {
        case RecognitionRule.POINT_IN_TIME:
            revenueArrangementItem.plan = PointInTimePlan.buildPlan(
                revenueArrangementItem.deliveryStartDate,
                revenueArrangementItem.deliveryEndDate,
                productRevenue,
                revenueArrangementItem.ssp,
                calendarConfig
            );
            break;
        case RecognitionRule.PROPORTIONAL_PERFORMANCE:
        case RecognitionRule.PERCENT_OF_COMPLETE:
            const plan = buildPlan(
                revenueArrangementItem,
                salesOrder,
                orgConfig,
                productRevenue,
                calendarConfig
            );
            PSHelper.updateToBaseServicePlan(plan, productRevenue);
            revenueArrangementItem.plan = plan;
            break;
        case RecognitionRule.TRACK_OTHER_PRODUCT_REVENUE:
            let raiplan = [];
            const productToTrack =
                revenueArrangementItem.ssp.productCodeToTrack;
            const baseProductRAI = findRevenueArrangementItemByProductCode(
                salesOrder,
                productToTrack,
                revenueArrangementItem.revenueArrangementId
            );
            if (baseProductRAI) {
                revenueArrangementItem.deliveryStartDate =
                    baseProductRAI.deliveryStartDate;
                revenueArrangementItem.deliveryEndDate =
                    baseProductRAI.deliveryEndDate;
                const itemPlan = buildPlan(
                    revenueArrangementItem,
                    salesOrder,
                    orgConfig,
                    productRevenue,
                    calendarConfig
                );
                raiplan = calculatePlanAmount(
                    itemPlan,
                    productRevenue,
                    baseProductRAI
                );
            } else {
                errors.add(Codes.SO_14, {
                    salesOrderId: salesOrder.id,
                    salesOrderItemId: revenueArrangementItem.salesOrderItemId,
                    productCodeToTrack: productToTrack
                });
            }
            revenueArrangementItem.plan = raiplan;
            break;
        default:
            revenueArrangementItem.plan = buildPlan(
                revenueArrangementItem,
                salesOrder,
                orgConfig,
                productRevenue,
                calendarConfig,
                customCode
            );
    }

    prefillZeroAtStart(revenueArrangementItem.plan, salesOrder, calendarConfig);
}

const getMaxBillingDate = (salesOrder) => {
    let maxBillingDate;
    // pick max billing date
    if (
        salesOrder.billingSchedule &&
        salesOrder.billingSchedule.billingScheduleItem
    ) {
        for (const bsi of salesOrder.billingSchedule.billingScheduleItem) {
            const schedule = bsi.schedule;
            if (Array.isArray(schedule) && schedule.length) {
                const sortedByBillingDate = _.sortBy(
                    schedule,
                    (s) => s.billingDate
                );
                const lastBilling =
                    sortedByBillingDate[sortedByBillingDate.length - 1];
                const lastBillingDate = lastBilling.billingDate;

                if (!maxBillingDate) {
                    maxBillingDate = lastBillingDate;
                } else if (lastBillingDate > maxBillingDate) {
                    maxBillingDate = lastBillingDate;
                }
            }
        }
    }

    return maxBillingDate;
};

/**
 * If we have billing that is coming after the effective date of the
 * revenue arrangement then we want to add those periods in the plan
 * with 0 so that when transactions are generated they don't have any missing
 * rows in it
 * @param {*} plan
 * @param {*} salesOrder
 * @param {*} calendarConfig
 * @returns
 */

export function addBillingPeriodsToPlan(plan, salesOrder, calendarConfig) {
    if (plan.length == 0) return;

    const maxBillingDate = getMaxBillingDate(salesOrder);

    if (!maxBillingDate) return;

    const lastPlanActgPeriod = plan[plan.length - 1].actgPeriod;

    const maxBillingEffectivePeriod = Period.toActgPeriod(
        maxBillingDate,
        calendarConfig
    );

    if (maxBillingEffectivePeriod.period <= lastPlanActgPeriod.period) return;

    const periods = Period.periodsBetween(
        lastPlanActgPeriod.period,
        maxBillingEffectivePeriod.period,
        false,
        true
    );

    if (periods.length == 0) return;

    let actgPeriod = lastPlanActgPeriod;
    for (let period of periods) {
        actgPeriod = actgPeriod.get(period);

        plan.push({
            actgPeriod,
            planAmount: 0,
            percentRecognized: 0
        });
    }
}

export function updateQuantityPlan(revenueArrangementItem, plan) {
    let availableQuantity = revenueArrangementItem.salesOrderItem.quantity;
    let originalBooking = revenueArrangementItem.productRevenue;
    let totalBooking = originalBooking;
    let unitCost = totalBooking / availableQuantity;

    var last = plan[plan.length - 1];

    plan.forEach((element) => {
        if (element.quantity > availableQuantity) {
            element.quantity = availableQuantity;
        }

        if (element == last && element.quantity < availableQuantity) {
            element.quantity = availableQuantity;
        }

        element.planAmount = unitCost * element.quantity;
        element.percentRecognized = element.planAmount / originalBooking;

        totalBooking -= element.planAmount;
        element.cummulativeTotal = originalBooking - totalBooking;
        element.cummulativePercentRecognized =
            element.cummulativeTotal / originalBooking;

        availableQuantity -= element.quantity;
    });
}

export function doRACleanup(ra) {
    const cleanupSOI = (soi) => {
        delete soi.revenueAccountNumber;
        delete soi.deferredRevenueAccountNumber;
        delete soi.contraRevenueAccountNumber;
        delete soi.commissionAccountNumber;
        delete soi.deferredCommissionAccountNumber;
        delete soi.commissionAccumulatedAmortizationAccountNumber;
        delete soi.contraCommissionExpenseAccountNumber;

        delete soi.product.jobId;
        delete soi.product.lastModifiedBy;
        delete soi.product.lastModifiedDate;
        delete soi.product.rowNum;
    };

    const cleanupExpenseItem = (expItem, removePlan = false) => {
        delete expItem.rowNum;

        if (removePlan) {
            delete expItem.plan;
        }

        if (expItem.expenseRule) {
            delete expItem.expenseRule.xlRow;
            delete expItem.expenseRule.lastModifiedBy;
            delete expItem.expenseRule.lastModifiedDate;
        }

        if (expItem.salesPerson) {
            delete expItem.salesPerson.new;
            delete expItem.salesPerson.lastModifiedBy;
        }
    };

    for (const rai of ra.revenueArrangementItem) {
        // 1 cleanup rai level keys
        delete rai.liabilityAccountId;
        delete rai.revenueAccountId;
        delete rai.contraRevenueAccountId;
        delete rai.deferredCommissionAccountId;
        delete rai.commissionAmortizationAccountId;
        delete rai.contraCommissionExpenseAccountId;
        delete rai.commissionAccumulatedAmortizationAccountId;

        if (rai.ssp) {
            delete rai.ssp.xlRow;
            delete rai.ssp.lastModifiedBy;
            delete rai.ssp.lastModifiedDate;
        }

        // 2. cleanup sales order item level keys
        if (rai.salesOrderItem) {
            cleanupSOI(rai.salesOrderItem);
        }

        // 2. cleanup expense item level keys
        if (rai.expenseItems && rai.expenseItems.length) {
            for (const expItem of rai.expenseItems) {
                cleanupExpenseItem(expItem);
            }
        }

        // 3. cleanup snapshot level keys
        if (rai.snapshots) {
            if (rai.snapshots.ssp) {
                delete rai.snapshots.ssp.xlRow;
                delete rai.snapshots.ssp.lastModifiedBy;
                delete rai.snapshots.ssp.lastModifiedDate;
            }

            if (rai.snapshots.salesOrderItem) {
                cleanupSOI(rai.snapshots.salesOrderItem);
            }

            if (
                rai.snapshots.expenseItems &&
                rai.snapshots.expenseItems.length
            ) {
                delete rai.snapshots.expenseItems;
            }
        }
    }
}

/**
 * Applies Prospective Modification to the sales order using the previous revenueArrangement
 * @param {*} salesOrder the sales order to apply modification
 * @param {*} revenueArrangement the new revenueArrangement
 * @param {*} previousRevenueArrangement the previous revenueArrangement
 * @param {*} orgConfig
 * @param {*} orgConfigByKey
 * @param {*} calendarConfig
 */
function applyProspectiveModification(
    salesOrder,
    revenueArrangement,
    previousRevenueArrangement,
    orgConfig,
    orgConfigByKey,
    calendarConfig
) {
    let revenueArrangementItemsToPush = [];

    const endDateInclusiveConfig = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive =
        endDateInclusiveConfig && endDateInclusiveConfig.value;

    const isAfter = endDateInclusive ? Utils.isSameOrAfter : Utils.isAfter;

    const notEmpty = (value) => !_.isEmpty(value);

    const getModificationPeriodPlan = (item, plan, modificationPeriod) => {
        let modPeriodPlans = plan.filter(
            (p) => p.actgPeriod.period == modificationPeriod.period
        );
        let modPeriodPlan;
        if (modPeriodPlans && modPeriodPlans.length) {
            modPeriodPlan = modPeriodPlans.reduce(
                (finalPlan, p) => {
                    finalPlan.planAmount += p.planAmount;
                    return finalPlan;
                },
                {
                    actgPeriod: modPeriodPlans[0].actgPeriod,
                    planAmount: 0
                }
            );
        }
        if (modPeriodPlan) {
            modPeriodPlan = Utils.copy(modPeriodPlan);
            const modActgPeriod = modPeriodPlan.actgPeriod;
            const isModAfterEndDate = Utils.isAfter(
                salesOrder.modificationDate,
                item.deliveryEndDate
            );
            let daysBeforeMod = Utils.getDayDiff(
                modActgPeriod.startDate,
                isModAfterEndDate
                    ? item.deliveryEndDate
                    : salesOrder.modificationDate
            );
            daysBeforeMod += endDateInclusive ? 1 : 0;
            let totalDays = modActgPeriod.numberOfDays();
            const endActgPeriod = Period.toActgPeriod(
                item.deliveryEndDate,
                calendarConfig
            );
            if (endActgPeriod.period == modificationPeriod.period) {
                totalDays = Utils.getDayDiff(
                    modActgPeriod.startDate,
                    item.deliveryEndDate
                );
                totalDays += endDateInclusive ? 1 : 0;
            }
            const oneDayPlanAmount = modPeriodPlan.planAmount / totalDays;
            modPeriodPlan.planAmount = oneDayPlanAmount * daysBeforeMod;
        }
        return modPeriodPlan;
    };

    // copy frozen expensePlan from the previousRevenueArrangement
    const copyExpenseFromPreviousArrangement = (
        raItem,
        previousRaItem,
        modificationPeriod
    ) => {
        raItem.expenseItems = Utils.copy(previousRaItem.expenseItems);
        raItem.expenseItems.forEach((expItem) => {
            expItem.deliveryEndDate = salesOrder.modificationDate;
            const frozenPlan =
                expItem.plan &&
                expItem.plan.filter((p) => {
                    return Utils.isBefore(
                        p.actgPeriod.period,
                        modificationPeriod.period
                    );
                });
            if (salesOrder.modificationDate > modificationPeriod.startDate) {
                const modPeriodPlan = getModificationPeriodPlan(
                    previousRaItem,
                    expItem.plan,
                    modificationPeriod
                );
                if (modPeriodPlan) {
                    frozenPlan.push(modPeriodPlan);
                }
            }
            expItem.plan = frozenPlan;
            expItem.allocatedExpense = expItem.plan.reduce(
                (total, plan) => total + plan.planAmount,
                0
            );
        });
    };

    // populate unrecognizeExpenseAmount [expenseAmount - frozenAllocatedExpense] for the expenseItems in the revenueArrangement
    const populateUnrecognizedExpense = () => {
        if (notEmpty(revenueArrangement.expenseItems)) {
            revenueArrangement.expenseItems.forEach((expItem) => {
                expItem.unrecognizedExpenseAmount = expItem.expenseAmount;
            });
            revenueArrangement.revenueArrangementItem.forEach((raItem) => {
                if (ItemType.isFrozen(raItem.itemType)) {
                    raItem.expenseItems &&
                        raItem.expenseItems.forEach((expItem) => {
                            const expItemKey = ExpenseArrangementHelper.getExpenseItemUniqueKey(
                                expItem
                            );
                            const raExpItem = revenueArrangement.expenseItems.find(
                                (raExpItem) => {
                                    const raExpItemKey = ExpenseArrangementHelper.getExpenseItemUniqueKey(
                                        raExpItem
                                    );
                                    if (expItemKey == raExpItemKey) {
                                        return raExpItem;
                                    }
                                }
                            );
                            raExpItem.unrecognizedExpenseAmount -=
                                expItem.allocatedExpense;
                        });
                }
            });
        }
    };

    // for a frozen item, copy the existing plan from the previous revenueArrangement
    const copyPlanFromPreviousArrangement = (raItem, previousRaItem) => {
        // assume, that the complete previous plan is frozen and modify below if needed
        let frozenPlan = previousRaItem.plan;
        const modificationPeriod = Period.toActgPeriod(
            salesOrder.modificationDate,
            calendarConfig
        );
        switch (previousRaItem.recognitionRule.id) {
            case RecognitionRule.POINT_IN_TIME: {
                const {
                    deliveryStartDate,
                    deliveryEndDate,
                    ssp,
                    plan
                } = previousRaItem;
                const recognizedDate =
                    ssp.recognizedOn === "endDate"
                        ? deliveryEndDate
                        : deliveryStartDate;
                // modification happens before the recognizedDate, so the frozen plan will be empty
                if (
                    Utils.isSameOrBefore(
                        salesOrder.modificationDate,
                        recognizedDate
                    )
                ) {
                    frozenPlan = [
                        {
                            actgPeriod: plan[0].actgPeriod,
                            planAmount: 0
                        }
                    ];
                }
                break;
            }
            case RecognitionRule.PROPORTIONAL_PERFORMANCE:
            case RecognitionRule.PERCENT_OF_COMPLETE: {
                // modification date is between the start and end date of the item, need to recalculate only the frozen part
                if (
                    isAfter(
                        raItem.salesOrderItem.endDate,
                        salesOrder.modificationDate
                    )
                ) {
                    if (
                        salesOrder.modificationDate >
                        modificationPeriod.startDate
                    ) {
                        const raItem = Utils.copy(previousRaItem);
                        // modification date is not the first day of the month, recalculate the frozen plan
                        buildPSPlan(
                            raItem,
                            salesOrder,
                            revenueArrangement,
                            null,
                            calendarConfig,
                            orgConfig,
                            orgConfigByKey,
                            true
                        );
                        frozenPlan = raItem.plan.filter((p) => {
                            return Utils.isSameOrBefore(
                                p.actgPeriod.period,
                                modificationPeriod.period
                            );
                        });
                    } else {
                        frozenPlan = previousRaItem.plan.filter((p) => {
                            return Utils.isBefore(
                                p.actgPeriod.period,
                                modificationPeriod.period
                            );
                        });
                    }
                }
                break;
            }
            default: {
                // consider ratable as the default rule
                frozenPlan = previousRaItem.plan.filter((p) => {
                    return Utils.isBefore(
                        p.actgPeriod.period,
                        modificationPeriod.period
                    );
                });
                if (
                    salesOrder.modificationDate > modificationPeriod.startDate
                ) {
                    const modPeriodPlan = getModificationPeriodPlan(
                        previousRaItem,
                        previousRaItem.plan,
                        modificationPeriod
                    );
                    if (modPeriodPlan) {
                        frozenPlan.push(modPeriodPlan);
                    }
                }
                break;
            }
        }
        raItem.plan = frozenPlan.map((p) => Utils.copy(p));
        raItem.productRevenue = frozenPlan.reduce(
            (total, plan) => total + plan.planAmount,
            0
        );
        if (notEmpty(previousRaItem.expenseItems)) {
            copyExpenseFromPreviousArrangement(
                raItem,
                previousRaItem,
                modificationPeriod
            );
        }
    };

    // merge plans of SPLIT_FROZEN and SPLIT_UNRECOGNIZED to a single plan
    const mergePlan = (plan1, plan2) => {
        const mergedPlan = plan1 || [];
        if (plan2 && plan2.length) {
            mergedPlan.push(...plan2);
        }
        return mergedPlan;
    };

    // merge expense plans of SPLIT_FROZEN and SPLIT_UNRECOGNIZED to a single plan
    const mergeExpenseItems = (activeItem, frozenItem) => {
        const expenseItems = Utils.copy(activeItem.expenseItems) || [];
        frozenItem.expenseItems &&
            frozenItem.expenseItems.forEach((expItem) => {
                const uniqueKey = ExpenseArrangementHelper.getExpenseItemUniqueKey(
                    expItem
                );
                const activeExpItem = expenseItems.find((activeExpItem) => {
                    if (
                        ExpenseArrangementHelper.getExpenseItemUniqueKey(
                            activeExpItem
                        ) === uniqueKey
                    ) {
                        return activeExpItem;
                    }
                });
                activeExpItem.expenseAmount = expItem.expenseAmount;
                activeExpItem.plan = mergePlan(
                    expItem.plan,
                    activeExpItem.plan
                );
                activeExpItem.allocatedExpense = activeExpItem.plan.reduce(
                    (total, plan) => total + plan.planAmount,
                    0
                );
            });
        return expenseItems;
    };

    // get the previous revenueArrangementItem for the sales order item
    const getPreviousRaItem = (salesOrderItem) => {
        const previousRaItems = previousRevenueArrangement.revenueArrangementItem.filter(
            (rai) => rai.salesOrderItemId === salesOrderItem.id
        );
        if (previousRaItems && previousRaItems.length == 2) {
            // If the salesOrderItem already has a prospective modification, we will have two raItems
            // one item of type SPLIT_FROZEN and second of type SPLIT_UNRECOGNIZED
            const frozenItem = previousRaItems.find(
                (item) => item.itemType == ItemType.SPLIT_FROZEN
            );
            const activeItem = previousRaItems.find(
                (item) => item.itemType != ItemType.SPLIT_FROZEN
            );
            const plan = mergePlan(frozenItem.plan, activeItem.plan);
            let deliveryLogs;
            if (notEmpty(activeItem.deliveryLogs)) {
                deliveryLogs = notEmpty(frozenItem.deliveryLogs)
                    ? frozenItem.deliveryLogs
                    : [];
                deliveryLogs.push(...activeItem.deliveryLogs);
            }
            let expenseItems;
            if (notEmpty(activeItem.expenseItems)) {
                expenseItems = mergeExpenseItems(activeItem, frozenItem);
            }
            return {
                plan,
                ssp: activeItem.ssp,
                salesOrderItem: activeItem.salesOrderItem,
                deliveryEndDate: activeItem.deliveryEndDate,
                productRevenue:
                    frozenItem.productRevenue + activeItem.productRevenue,
                deliveryLogs,
                expenseItems,
                unrecognizedRevenue: activeItem.unrecognizedRevenue,
                recognitionRule: activeItem.recognitionRule
            };
        }
        return previousRaItems[0];
    };

    const isReprocess =
        previousRevenueArrangement.modificationType ==
            ModificationType.PROSPECTIVE &&
        salesOrder.modificationDate ==
            previousRevenueArrangement.modificationDate;

    revenueArrangement.revenueArrangementItem.forEach((item) => {
        const { startDate, endDate, extendedSalePrice } = item.salesOrderItem;
        const currentSoItem = item.salesOrderItem;
        const currentSSP = item.ssp;
        const previousRaItem = getPreviousRaItem(item.salesOrderItem);

        if (Utils.isBefore(startDate, salesOrder.modificationDate)) {
            if (!previousRaItem) {
                item.itemType = ItemType.FROZEN;
                if (isAfter(endDate, salesOrder.modificationDate)) {
                    item.itemType = ItemType.SPLIT_UNRECOGNIZED;
                    item.deliveryStartDate = salesOrder.modificationDate;
                    item.unrecognizedRevenue = extendedSalePrice;
                }
                return;
            }

            // the sales order item falls under modification, mark the created item as SPLIT_FROZEN
            // and copy the existing plan till the modification date
            item.itemType = ItemType.FROZEN;
            item.recognitionRule = previousRaItem.recognitionRule;
            item.ssp = previousRaItem.ssp;
            item.salesOrderItem = previousRaItem.salesOrderItem;
            item.relativeSalePricePercent = 0.0;
            copyPlanFromPreviousArrangement(item, previousRaItem);

            if (isAfter(endDate, salesOrder.modificationDate)) {
                // modification date is between the item's start and end date, split the item to create a new unrecognized raItem
                let unrecognizedRevenue, unrecognizedQuantity;
                // the unrecognizedRevenue will be the future revenue to be recognized for the item (excluding the previous recognized amount)
                if (isReprocess) {
                    unrecognizedRevenue = previousRaItem.unrecognizedRevenue;
                    unrecognizedQuantity = previousRaItem.unrecognizedQuantity;
                } else {
                    const adjustment =
                        extendedSalePrice -
                        previousRaItem.salesOrderItem.extendedSalePrice;
                    unrecognizedRevenue =
                        previousRaItem.productRevenue -
                        item.productRevenue +
                        adjustment;
                    const recognizedQuantity =
                        previousRaItem.salesOrderItem.quantity *
                        (item.productRevenue / previousRaItem.productRevenue);
                    unrecognizedQuantity =
                        currentSoItem.quantity - recognizedQuantity;
                }
                if (
                    unrecognizedRevenue == 0 &&
                    currentSSP.recognitionRuleId != RecognitionRule.RATABLE
                ) {
                    return;
                }

                const unrecognizedRaItem = newRevenueArrangementItem(
                    currentSoItem,
                    revenueArrangement.id,
                    ItemType.SPLIT_UNRECOGNIZED
                );
                unrecognizedRaItem.isActive = item.isActive;
                unrecognizedRaItem.deliveryStartDate =
                    salesOrder.modificationDate;
                unrecognizedRaItem.deliveryEndDate = item.deliveryEndDate;
                unrecognizedRaItem.ssp = currentSSP;
                unrecognizedRaItem.unrecognizedRevenue = unrecognizedRevenue;
                unrecognizedRaItem.unrecognizedQuantity = unrecognizedQuantity;

                // update the frozen item to split frozen
                item.itemType = ItemType.SPLIT_FROZEN;
                item.deliveryEndDate = salesOrder.modificationDate;

                revenueArrangementItemsToPush.push(unrecognizedRaItem);
            }
        } else {
            // If the item starts after the modificationDate, consider it as an UNRECOGNIZED item
            if (previousRaItem) {
                if (isReprocess && previousRaItem.unrecognizedRevenue != null) {
                    item.itemType = ItemType.SPLIT_UNRECOGNIZED;
                    item.unrecognizedRevenue =
                        previousRaItem.unrecognizedRevenue;
                } else if (!isReprocess) {
                    item.itemType = ItemType.SPLIT_UNRECOGNIZED;
                    const adjustment =
                        extendedSalePrice -
                        previousRaItem.salesOrderItem.extendedSalePrice;
                    item.unrecognizedRevenue =
                        previousRaItem.productRevenue + adjustment;
                }
            }
        }
    });

    populateUnrecognizedExpense();

    revenueArrangement.revenueArrangementItem.push(
        ...revenueArrangementItemsToPush
    );
}

function buildPSPlan(
    item,
    salesOrder,
    revenueArrangement,
    errors,
    calendarConfig,
    orgConfig,
    orgConfigByKey,
    computePreModPlan = false
) {
    // Taking backup of original Plan
    if (!item.originalPlan) {
        item.originalPlan = Utils.copy(item.plan);
    }

    const enableBetaFeatures =
        (orgConfigByKey["properties/enableBetaFeatures"] &&
            orgConfigByKey["properties/enableBetaFeatures"].value) ||
        false;

    const incrementalPercentComplete =
        (orgConfigByKey["properties/service-delivery/percentage-incremental"] &&
            orgConfigByKey["properties/service-delivery/percentage-incremental"]
                .value) ||
        false;

    const revenueWithPPA =
        (orgConfigByKey["properties/enable_prior_period_adj"] &&
            orgConfigByKey["properties/enable_prior_period_adj"].value) ||
        false;

    PSHelper.updatePSPlan(
        item,
        salesOrder,
        revenueArrangement,
        errors,
        enableBetaFeatures,
        calendarConfig,
        incrementalPercentComplete,
        orgConfig,
        revenueWithPPA,
        computePreModPlan
    );
}

export function mergeRevenueArrangementItems(
    revenueArrangementItems,
    options = {}
) {
    const { mergeExpenseItems } = options;
    const mergedRevenueArrangementItems = [];
    const raiBySoiId = Utils.groupByOnArray(
        revenueArrangementItems,
        (item) => item.salesOrderItemId
    );
    Object.keys(raiBySoiId).forEach((salesOrderItemId) => {
        const rai = raiBySoiId[salesOrderItemId];
        if (rai && rai.length == 2) {
            const frozenItem = rai.find(
                (item) => item.itemType == ItemType.SPLIT_FROZEN
            );
            const unrecognizedItem = rai.find(
                (item) => item.itemType == ItemType.SPLIT_UNRECOGNIZED
            );
            const mergedItem = Utils.copy(unrecognizedItem);
            mergedItem.productRevenuePreMod = frozenItem.productRevenue;
            mergedItem.productRevenuePostMod = unrecognizedItem.productRevenue;
            mergedItem.productRevenue =
                frozenItem.productRevenue + unrecognizedItem.productRevenue;
            mergedItem.terminatedProductRevenue =
                (frozenItem.terminatedProductRevenue || 0) +
                (unrecognizedItem.terminatedProductRevenue || 0);
            mergedItem.deliveryStartDate = frozenItem.deliveryStartDate;

            if (
                mergeExpenseItems &&
                mergedItem.expenseItems &&
                frozenItem.expenseItems
            ) {
                mergedItem.expenseItems.forEach((expenseItem) => {
                    const expenseItemKey = ExpenseArrangementHelper.getExpenseItemUniqueKey(
                        expenseItem
                    );
                    const frozenExpenseItem =
                        frozenItem.expenseItems &&
                        frozenItem.expenseItems.find((expenseItem) => {
                            const frozenExpenseItemKey = ExpenseArrangementHelper.getExpenseItemUniqueKey(
                                expenseItem
                            );
                            if (frozenExpenseItemKey == expenseItemKey) {
                                return expenseItem;
                            }
                        });
                    if (frozenExpenseItem) {
                        expenseItem.allocatedExpense +=
                            frozenExpenseItem.allocatedExpense;
                        expenseItem.expenseAmount =
                            frozenExpenseItem.expenseAmount;
                    }
                });
            }

            mergedRevenueArrangementItems.push(mergedItem);
        } else {
            const raItem = rai[0];
            if (ItemType.isFrozen(raItem.itemType)) {
                raItem.productRevenuePreMod = raItem.productRevenue;
            } else {
                raItem.productRevenuePostMod = raItem.productRevenue;
            }
            mergedRevenueArrangementItems.push(raItem);
        }
    });
    return mergedRevenueArrangementItems;
}
